/*!

=========================================================
* Material Dashboard React - v1.9.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/material-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from 'react-redux';
import configureStore from './store';
import App from './App';
import { loggedIn } from './utils/auth';
import { fetchUser } from './actions/user';
import { takeIfExists } from './utils/localStorage';
import registerServiceWorker from './registerServiceWorker';
import './utils/array-extensions';
import './custom.css';
import { LicenseInfo } from '@material-ui/x-grid';
import "assets/css/material-dashboard-react.css?v=1.9.0";
import HttpsRedirect from 'react-https-redirect';
import * as Sentry from "@sentry/react";
LicenseInfo.setLicenseKey('e53c411dca2db066206b993be30b47e3T1JERVI6MTk0NjcsRVhQSVJZPTE2Mzk2MDg1MTMwMDAsS0VZVkVSU0lPTj0x');

const store = configureStore();
loggedIn() && store.dispatch(fetchUser({Id:takeIfExists('id')}));

Sentry.init({
  dsn: process.env.SENTRY_DSN,
  environment: process.env.IA_ENV,
});

ReactDOM.render(
  <HttpsRedirect>
  <Provider store={store}>
  <App />
  </Provider>
  </HttpsRedirect>,
  document.getElementById("root")
);


registerServiceWorker();


//
