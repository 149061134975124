// REACT
import React from "react";
import Button from '../components/CustomButtons/Button';
import { getDateString, getStatusText } from "../utils/generic";
import LinearBar from "../components/ProgressBar/LinearBar.js";
import moment from "moment";


export const getRole = (role) => {

  let roleFull = "Basic";

  if(role === "a")
  roleFull = "Admin"

  return roleFull;
}

export const getModelTypeName = (id) => {

  let name = "GGIT v1";

  if(id === 2)
  name = "Daycent cr v1"

  return name;
}

export const getPercent = (percent) => {

    let val = isNaN(percent) ? 0 : parseInt(percent);

    return <LinearBar value={val} />
  }


  export const getDownloadLink = (path) => {

    return (path !== null && path !== '') ? 'true' : 'false';
  }

  export const getElapsedTime = val =>
  {
    let dt = moment.utc(val);
    let dt2 = moment.utc();
    let dateS = '0 secs';

    if (dt) {
      let millis = dt2.diff(dt);

      dateS = `${Math.floor(millis / 1000)} secs`;
    }

    return dateS;
  }

  export const getElapsedTimeHistory = (val, val2) => {
    let dt = moment.utc(val);
    let dt2 = moment.utc(val2);

    //console.log("Start Date", val);
    //console.log("End Date", val2);

    let dateS = '0 secs';

    if (dt && dt2) {
      let millis = dt2.diff(dt);
      // console.log("millis", millis)
      dateS = `${Math.floor(millis / 1000)} secs`;

      if (millis < 0)
        dateS = `N/A`;
    }

    return dateS;
  }

  export const  ledgerColumns = [
    { field: 'id', headerName: 'Id', width: 140, },
    { field: 'userIdString', headerName: 'User Email Id', width: 300 },
    { field: 'fileName', headerName: 'File Name', width: 400 },
      { field: 'successful', headerName: 'Successfully Finished', width: 100 },
      { field: 'rate', headerName: 'Rate', width: 100 },
    { field: 'runStart', headerName: 'Start', width: 200, valueFormatter: (params) =>  getDateString(params.value)},
    { field: 'runEnd', headerName: 'End', width: 200, valueFormatter: (params) => getDateString(params.value)},
    {field: 'numberOfSeconds', headerName: 'Seconds', width: 170},
    {field: 'totalLocations', headerName: 'Total Locations', width: 170},
    {field: 'totalRuns', headerName: 'Total Runs', width: 170}
  ];

  export const  historyColumns = [
    { field: 'id', headerName: 'Id', width: 140 },
    { field: 'originalFileName', headerName: 'File Name', width: 400 },
    {
      field: 'status', headerName: 'Status', width: 200, valueFormatter: (params) =>
        getStatusText(params.value)
    },
    {
      field: 'runStart', headerName: 'Start', width: 200, valueFormatter: (params) =>
        getDateString(params.value)
    },
    {
      field: 'runEnd', hide:true, headerName: 'Elapsed Time', width: 170
    },
    {
      field: 'elapsedTime', headerName: 'Elapsed Time', width: 170, valueGetter: (params) =>
        getElapsedTimeHistory(params.getValue('runStart'), params.getValue('runEnd'))
    },
    {
      field: 'path', hide:true, headerName: 'Download Available'
    },
    {
      field: 'downloadAvailable', headerName: 'Download Available', width: 500, valueGetter: (params) =>
        getDownloadLink( params.getValue('path'))
    },
    {
      field: 'modelTypeId', headerName: 'Model Version', width: 170, valueGetter: (params) =>
        getModelTypeName(params.value)
    }
  ];

  export const  defaultSortHistory = [
    {
      field: 'id',
      sort: 'desc',
    },
  ];

  export const  defaultSortRun = [
    {
      field: 'runStart',
      sort: 'desc',
    },
  ];

  export const  ledgerSort = [
    {
      field: 'userIdString',
      sort: 'desc',
    },
  ];

  export const  userSort = [
    {
      field: 'email',
      sort: 'desc',
    },
  ];

  export const  runColumns = [
    { field: 'originalFileName', headerName: 'File Name', width: 500 },
    {
      field: 'runStart', headerName: 'Start', width: 200, valueFormatter: (params) =>
      getDateString(params.value)
    },
    { field: 'runEnd', headerName: 'Elapsed Time', width: 170, valueGetter: (params) => getElapsedTime(params.getValue('runStart')) },
    {
      field: 'percent', headerName: 'Percent Complete', width: 170, renderCell: (params) =>
        getPercent(params.value)
    },
    {
      field: 'modelTypeId', headerName: 'Model Version', width: 170, valueGetter: (params) =>
        getModelTypeName(params.value)
    }
  ];



  export const  qColumns = [
    { field: 'queuePosition', headerName: 'Queue Position', width: 150 },
    { field: 'fileName', headerName: 'File Name', width: 500 },
    {
      field: 'uploadDate', headerName: 'Upload Time', width: 170, valueFormatter: (params) =>
      getDateString(params.value)
    },
    {
      field: 'modelTypeId', headerName: 'Model Version', width: 170, valueGetter: (params) =>
        getModelTypeName(params.value)
    }
  ];

  export const  userColumns = [
    { field: 'id', headerName: 'User Id', width: 150 },
    { field: 'email', headerName: 'Email', width: 300 },
    { field: 'first', headerName: 'First', width: 150 },
    { field: 'last', headerName: 'Last', width: 150 },
    { field: 'company', headerName: 'Company', width: 150 },
    { field: 'ggitAccess', headerName: 'GGIT Access', width: 100 },
    { field: 'ggit30Access', headerName: 'GGIT 30cm Access', width: 100 },
    { field: 'crAccess', headerName: 'CR Access', width: 100 },
    { field: 'role', headerName: 'Role', width: 100, valueGetter: (params) => getRole(params.value)},

  ];

  export const  defaultSortQ = [
    {
      field: 'queuePosition',
      sort: 'desc',
    },
  ];


export const getDialogButton = (label, onClick) => {

    return <Button
        id={label}
        onClick={onClick}
        label={label}
    >
      {label}
      </Button>

};