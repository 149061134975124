import React from "react";
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
// core components
import styles from "assets/jss/material-dashboard-react/layouts/loginStyle.js";


const useStyles = makeStyles(styles);


export  default function GenericMessagePage(props) {
    let history = useHistory();

    // styles
    const classes = useStyles();

    let { message } = props;


    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <HowToRegIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        {message}
          </Typography>
                    <Grid item>
                        <Link onClick={() => history.push('/login')} variant="body2">
                            {"Login"}
                        </Link>
                    </Grid>
                </div>
            </Grid>
        </Grid>
    );
}
