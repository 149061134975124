import React, { useEffect, useCallback } from "react";
// @material-ui/core components
import { useSelector, useDispatch } from 'react-redux'
import { updateLoading } from "../../actions/mask";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import Icon from "@material-ui/core/Icon";
import classNames from "classnames";
import DynamicDialog from "components/Dialogs/Dialog.js";
import * as helpers from "../../helpers/helpers";
import { USER_SIGN_URL, USER_UPLOAD_CHECK, USER_FETCH_MODEL_TYPES, FETCH_ALL_MODEL_TYPES, USER_UPLOAD_THROUGH_CLOUD, USER_FETCH_MODEL_TEMPLATES } from "../../constants/api";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import { headerWithAuth, headerOnlyAuth } from "../../utils/auth";
import Checkbox from "@material-ui/core/Checkbox";
import {MenuItem, Select, Radio, RadioGroup, FormControl, FormControlLabel, FormLabel} from "@material-ui/core";

const buttonColors = [
    "primary",
    "info",
];

const styles = {
    cardCategoryWhite: {
        color: "rgba(255,255,255,.62)",
        margin: "0",
        fontSize: "14px",
        marginTop: "0",
        marginBottom: "0"
    },
    itemIcon: {
        width: "100%",
        height: "30px",
        fontSize: "24px",
        lineHeight: "30px",
        float: "left",
        marginRight: "15px",
        verticalAlign: "middle"
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none"
    },
    input: {
        display: 'none',
    }
};

const useStyles = makeStyles(styles);

export default function Upload() {
    const classes = useStyles();
    const [files, setFiles] = React.useState(null);
    const [selectedModel, setSelectedModel] = React.useState(1);
    const [showDialog, setDialog] = React.useState(false);
    const [urlChecked, setUrlChecked] = React.useState(false);
    const [emailChecked, setEmailChecked] = React.useState(true);
    const [monteCarloChecked, setMonteCarloChecked] = React.useState(true);
    const [customTemplate, setCustomTemplate] = React.useState([])
    const [outputType, setOutputType] = React.useState("Monthly")
    const [fullResultsChecked, setFullResultsChecked] = React.useState(false);
    const [dialogMessage, setDialogMessage] = React.useState('Your files were uploaded successfully! You can check run progress or to cancel a run from the Dashboard.');
    const [dialogKeepMounted, setDialogMount] = React.useState(true);

    /* Not sure we need these. 11/11/2020
    const [finalRun, setFinalRun] = React.useState(-1);
     const [startingRun, setStartingRun] = React.useState(-1);
     const [finalInput, setFinalInput] = React.useState(0);
     const [startingInput, setStartingInput] = React.useState(0);*/

    const [callbackUrl, setCallbackUrl] = React.useState("");
    const [userModelTypes, setUserModelTypes] = React.useState([]);
    const [allModelTypes, setAllModelTypes] = React.useState([]);
    const [userModelTemplates, setUserModelTemplates] = React.useState([]);
    const [email, setEmailChanged] = React.useState("");
    const [dialogTitle, setDialogTitle] = React.useState('Upload Success!');
    const [uploadCalled, setUploadCalled] = React.useState(false);
    const [uploadError, setUploadError] = React.useState(false);
    const [isFirst, setIsFirst] = React.useState(true);
    const currentUser = useSelector(state => state.user)
    const dispatch = useDispatch()

    const handleModelChange = (event) => {
        setSelectedModel(parseInt(event.target.value));
    };

    const handleTemplateChange = (event) => {
        setCustomTemplate(event.target.value);
    };

    const handleOutputTypeChange = (event) => {
        setOutputType(event.target.value);
    };

    const handleUrlCheckChanged = (event) => {
        setUrlChecked(event.target.checked);
    };

    const handleEmailCheckChanged = (event) => {
        setEmailChecked(event.target.checked);
    };

    const handleCallbackUrlChanged = (event) => {
        setCallbackUrl(event.target.value);
    };

    const handleEmailChanged = (event) => {
        // console.log(event);
        setEmailChanged(event.target.value);
    };

    const onFileChange = event => {
        //console.log(event.target.files);

        if (event.target.files !== null) {
            let numOfFiles = event.target.files.length;
            //console.log(numOfFiles);
            // console.log(event.target.files["0"]);
            let arrayOfFiles = [];

            if (numOfFiles > 0)
                for (var i = 0; i < numOfFiles; i++)
                    arrayOfFiles.push(event.target.files[i]);

            setFiles(arrayOfFiles);
            //      console.log(arrayOfFiles);
        }
    };

    const updateDialog = (title, message, mount, show) => {
        setDialogTitle(title)
        setDialogMessage(message);
        setDialogMount(mount)
        setDialog(show);
    }

    const checkUploadProgress = useCallback((notDone) => {
        var data = {
            Id: currentUser.id
        }

        if (notDone && uploadCalled) {
            setUploadCalled(false);
            fetch(USER_UPLOAD_CHECK, {
                method: "POST", body: JSON.stringify(data),
                headers: headerWithAuth()
            }).then(response => {
                if (response.status === 200) //Ok may have json data may not.
                {
                    //     console.log(response);
                    return response.json() // return the result of the inner promise, which is an error
                        .then((json) => {
                            //     console.log(json);
                            var totalUploadSize = json.totalUploadSize;
                            var currentUploadSize = json.currentUploadSize;
                            var inProgress = (totalUploadSize - currentUploadSize) > 1000 ? true : false;

                            if (inProgress && (uploadError === false)) //Must be in an active upload
                            {
                                // console.log("upload size", totalUploadSize, currentUploadSize);
                                var percent = Math.round((currentUploadSize / totalUploadSize) * 100);
                                updateDialog('Upload In Progress', 'Your files are being uploaded and placed in the queue. ' + percent + '% complete.', true, true);

                                setUploadCalled(true);
                            }

                            else {
                                if (!inProgress) {
                                    //  console.log("upload size", totalUploadSize, currentUploadSize);
                                    updateDialog('Upload Complete', '', false, true);
                                    setUploadCalled(false);
                                }

                                if (uploadError === true)
                                    updateDialog('Upload Failure', 'The server encountered an error while uploading your files. Please try again later.', false, true);
                            }

                            return null;
                        });
                }

                else //Some error before uploading the files
                {// return response.json();
                    updateDialog('Upload Failure', "Upload aborted. ", false, true);

                    return response.json() // return the result of the inner promise, which is an error
                        .then((json) => {
                            // console.log(json);
                            const { error } = json;
                            updateDialog('Upload Failure', 'The server encountered an error while uploading your files. ' + error + ' Please try again later.', false, true);
                        });
                }
            })
                .catch((error) => {
                    updateDialog('Upload Failure', 'The server encountered an error while uploading your files. ' + error + ' Please try again later.', false, true);
                    console.error('Error:', error);
                });
        }
    }, [currentUser.id, uploadCalled, uploadError]);

    const getGCSPolicy = (fileNameList) => {
        const formData = new FormData();
        formData.append("fileNames", JSON.stringify(fileNameList))
        formData.append("Id", currentUser.id);
        return fetch(USER_SIGN_URL, { method: "POST", body: formData, headers: headerOnlyAuth() })
    }

    const getStatusMessageFromSuccessFailure = (sucessFailure) => {
        let done = 0;
        let failed = 0;
        let progress = 0;
        for (let ind in sucessFailure) {

            let entry = sucessFailure[ind];
            if (entry.status) {
                if (entry.status === "uploaded") {
                    done++;
                }
                if (entry.status === "incomplete") {
                    progress++;
                }
                if (entry.status === "failed") {
                    failed++;
                }

            }


        }
        let retstring = done + " files uploaded. " + failed + " files failed. " + progress + " files uploading.";

        updateDialog('Upload In Progress', retstring , true, true);

    }

    const uploadFiles = (modelTypeId) => {
        if (files && files.length > 0) {

                // Create an object of formData

                let fileNameList = files.map(file => { return file.name });

                getGCSPolicy(fileNameList).then(response => {
                    response.json().then(allSignedUrls => {

                        var sucessFailure = fileNameList.map(name => { return { name: name, status: 'incomplete' } })
                        getStatusMessageFromSuccessFailure(sucessFailure);
                        var puts = allSignedUrls.map(signedUrl => {
                            var index = allSignedUrls.indexOf(signedUrl);

                            let file = files[index];
                            return fetch(signedUrl, { method: "put", body: file, origin: process.env.REACT_APP_MAIN_API_URL }).then(response => {

                                if (response.status === 200) {
                                    sucessFailure[index].status = "uploaded";
                                }
                                else {
                                    sucessFailure[index].status = "failed";
                                }

                                getStatusMessageFromSuccessFailure(sucessFailure);

                            })
                        });

                        Promise.all(puts).then(() => {
                            updateDialog('Upload Complete', 'Your files have been uploaded for processing and will be entered in the queue as soon as processing is complete.', false, true);
                            const formData = new FormData();

                            formData.append("Id", currentUser.id);

                            formData.append("fileNames", JSON.stringify(fileNameList))

                            formData.append("ModelTypeId", modelTypeId);

                            if (urlChecked)
                                formData.append("CallbackUrl", callbackUrl);

                            if (emailChecked)
                                formData.append("Email", email);

                            formData.append("monteCarlo", JSON.stringify(monteCarloChecked));
                            formData.append("fullResults", JSON.stringify(fullResultsChecked));
                            //This is so user's of the graphical interface can still get an upload progress.  Turned off otherwise.
                            formData.append("includeUploadProgress", JSON.stringify(true));
                            formData.append("OutputType", outputType);
                            formData.append("Template", customTemplate);
                            fetch(USER_UPLOAD_THROUGH_CLOUD, { method: "POST", body: formData, headers: headerOnlyAuth() }).then(response => {
                                if (response.status === 200) //Ok may have json data may not.
                                {
                                    return response.json() // return the result of the inner promise, which is an error
                                        .then((json) => {
                                            var message = json.message;
                                            var error = json.error;
                                            if (error) {
                                                error = " However there were some errors with the upload:" + error;
                                            }

                                            updateDialog('Upload Finished', message + " \n" + error, false, true);
                                            setUploadCalled(false);

                                            return null;
                                        });
                                }

                                else //Some error before uploading the files
                                {// return response.json();
                                    setUploadCalled(false);
                                    updateDialog('Upload Failure', "", false, true);

                                    return response.json() // return the result of the inner promise, which is an error
                                        .then((json) => {

                                            const { error } = json;

                                            updateDialog('Upload Failure', 'The server encountered an error while uploading your files. ' + error + ' Please try again later.', false, true);
                                        });
                                }
                            })
                                .catch((error) => {
                                    setUploadCalled(false);
                                    setUploadError(true);
                                    updateDialog('Upload Failure', 'The server encountered an error while uploading your files. ' + error + ' Please try again later.', false, true);
                                    console.error('Error:', error);
                                });
                        });
                    });
                });
            }
            else {
                updateDialog('No valid file types selected', 'Please select any combination of xml, json or csv files. Optionally you may bundle files in .zip folder.', false, true);
            }
        };

        const fetchAllModelTypes = useCallback(() => {
            dispatch(updateLoading({ loading: true }));

            fetch(FETCH_ALL_MODEL_TYPES, { method: "GET", headers: headerWithAuth() }).then(response => {
                if (response.status === 200) //Ok may have json data may not.
                {
                    return response.json() // return the result of the inner promise, which is an error
                        .then((json) => {
                            //console.log(json);
                            setAllModelTypes(json);
                            dispatch((updateLoading({ loading: false })));
                            return null;
                        }).catch((error) => {
                            console.error('Error:', error);
                            dispatch((updateLoading(false)));
                        });
                }

                else //Some error before uploading the files
                {// return response.json();
                    dispatch(updateLoading({ loading: false }));
                    return null;
                }
            }).catch((error) => {
                console.error('Error:', error);
                dispatch((updateLoading(false)));
            });
        }, [dispatch]);

        const fetchUserModelTypes = useCallback(() => {
            dispatch(updateLoading({ loading: true }));

            fetch(USER_FETCH_MODEL_TYPES, { method: "GET", headers: headerWithAuth() }).then(response => {
                if (response.status === 200) //Ok may have json data may not.
                {
                    return response.json() // return the result of the inner promise, which is an error
                        .then((json) => {
                            //  console.log('usermodelfetch',json);
                            setUserModelTypes(json);
                            dispatch((updateLoading({ loading: false })));
                            return null;
                        }).catch((error) => {
                            console.error('Error:', error);
                            dispatch((updateLoading(false)));
                        });
                }
                else //Some error before uploading the files
                {// return response.json();
                    dispatch(updateLoading({ loading: false }));
                    return null;
                }
            }).catch((error) => {
                console.error('Error:', error);
                dispatch((updateLoading(false)));
            });
        }, [dispatch]);

        const fetchUserModelTemplates = useCallback(() => {
            dispatch(updateLoading({ loading: true }));

            fetch(USER_FETCH_MODEL_TEMPLATES, { method: "GET", headers: headerWithAuth() }).then(response => {
                if (response.status === 200) //Ok may have json data may not.
                {
                    return response.json() // return the result of the inner promise, which is an error
                        .then((json) => {
                            //  console.log('usermodelfetch',json);
                            setUserModelTemplates(json);
                            dispatch((updateLoading({ loading: false })));
                            return null;
                        }).catch((error) => {
                            console.error('Error:', error);
                            dispatch((updateLoading(false)));
                        });
                }
                else //Some error before uploading the files
                {// return response.json();
                    dispatch(updateLoading({ loading: false }));
                    return null;
                }
            }).catch((error) => {
                console.error('Error:', error);
                dispatch((updateLoading(false)));
            });
        }, [dispatch]);

        /*Check if user has access to this API. userModelTypes should be a list of all user userModelTypes*/
        const hasAccessToApi = (id) => {
            return typeof userModelTypes.find(m => m.id === id) !== 'undefined'
        }

        const resetDialogs = () => setDialog(false);

        var okButton = helpers.getDialogButton("Ok", resetDialogs);
        var dialogButtons = [okButton];

    useEffect(() => {    // Update the document title using the browser API
        //  console.log('upload called', uploadCalled);
        if (uploadCalled) {
            const timer = setInterval(() => checkUploadProgress(true), 3000);
            return () => clearInterval(timer);
        }
    }, [uploadCalled, checkUploadProgress]);

    useEffect(() => {    // Update the document title using the browser API
        if (currentUser.email)
            if (isFirst) {
                setEmailChanged(currentUser.email);
                fetchUserModelTypes();
                fetchAllModelTypes();
                fetchUserModelTemplates();
                setIsFirst(false);
            }
    }, [currentUser.email, isFirst, fetchAllModelTypes, fetchUserModelTypes, fetchUserModelTemplates]);

        return (
            <div>
                <GridContainer direction='column' justify="flex-start" alignItems="flex-start">
                    <GridItem xs={12} sm={12} md={12}>
                        <Card >
                            <CardHeader color="primary">
                                <h4 className={classes.cardTitleWhite}>Upload Run Files</h4>
                                <p className={classes.cardCategoryWhite}>(XML, JSON)</p>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={12}>
                                        <h4 >Upload any combination of individual files or a .zip folders (max size per upload = 250MB).</h4>
                                    </GridItem>

                                    <GridItem xs={3} sm={3} md={3}>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={12}>
                                                <h4 style={{ marginBottom: 10 }} >Selected Files: </h4>
                                                <input type="file" accept=".zip, .json, .xml" onChange={onFileChange} multiple />
                                            </GridItem>

                                            <GridItem style={{ maxHeight: 175 }} xs={12} sm={12} md={12}>
                                                {files && files.length > 0 && files.map((file, index) =>
                                                    <div key={"file" + index}>
                                                        {file.name}
                                                    </div>
                                                )
                                                }
                                            </GridItem>

                                            <GridItem xs={12} sm={12} md={12}>
                                                <FormControl style={{ marginTop: 20 }}>
                                                    <FormLabel id="model-radio-buttons-group">Model Type</FormLabel>
                                                    <RadioGroup
                                                        aria-labelledby="model-radio-buttons-group"
                                                        name="controlled-radio-buttons-group"
                                                        value={selectedModel}
                                                        onChange={handleModelChange}
                                                    >
                                                        {userModelTypes.map((m) => (
                                                            <FormControlLabel
                                                                key={"radioButton" + m.id}
                                                                value={m.id}
                                                                control={<Radio />}
                                                                label={m.name}
                                                            />
                                                        ))}
                                                    </RadioGroup>
                                                </FormControl>
                                            </GridItem>
                                            {userModelTypes && userModelTypes.length > 0 && 
                                                <GridItem xs={12} sm={6} md={6}>
                                                    <div style={{ marginTop: 10 }}>
                                                        <Button color={buttonColors[0]} onClick={() => { uploadFiles(selectedModel); }} > {"Submit"} </Button>
                                                    </div>
                                                </GridItem>
                                            }
                                            {!(userModelTypes && userModelTypes.length) &&
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <h4 >You currently are not authorized for any of the simulation models. Please email <a href="mailto: technicalsupport@axiossoft.com">technicalsupport@axiossoft.com</a> to request access.</h4>
                                                </GridItem>
                                            }

                                        </GridContainer>
                                    </GridItem>

                                    <GridItem xs={9} sm={9} md={9}>
                                        <GridContainer>
                                            {(selectedModel === 2 && (currentUser.role === 'a' || hasAccessToApi(selectedModel))) &&//Admin users have access to all model, check other users
                                                <GridContainer alignItems="center">
                                                    <GridItem xs={12} sm={12} md={12}>
                                                        <h4 >Set your run preferences</h4>
                                                    </GridItem>

                                                    <GridItem xs={3} sm={3} md={3}>
                                                        <div style={{ marginTop: 10 }}>
                                                            <h4>Output Type:</h4>
                                                        </div>
                                                    </GridItem>
                                                    <GridItem xs={9} sm={9} md={9}>
                                                        <Select
                                                            value={outputType}
                                                            onChange={handleOutputTypeChange}
                                                            fullWidth
                                                            label="Select an option"
                                                            style={{ marginTop: 10, marginRight: 10 }}
                                                          >
                                                            <MenuItem key="monthly" value="Monthly">Monthly</MenuItem>
                                                            <MenuItem key="daily" value="DailyWithN2O">Daily With N2O</MenuItem>
                                                            <MenuItem key="custom" value="CustomTemplate">Custom Template</MenuItem>
                                                        </Select>
                                                    </GridItem>

                                                    {outputType === "CustomTemplate" && 
                                                        <GridItem xs={3} sm={3} md={3}>
                                                            <div style={{ marginTop: 10 }}>
                                                                <h4>Template:</h4>
                                                            </div>
                                                        </GridItem>
                                                    }
                                                    {outputType === "CustomTemplate" && 
                                                        <GridItem xs={9} sm={9} md={9}>
                                                            <Select
                                                                value={customTemplate}
                                                                onChange={handleTemplateChange}
                                                                fullWidth
                                                                label="Select an option"
                                                                style={{ marginTop: 10, marginRight: 10 }}
                                                            >
                                                                <MenuItem value="" disabled>
                                                                    Select an option
                                                                </MenuItem>
                                                                {userModelTemplates && userModelTemplates.length > 0 && userModelTemplates.map((template, index) =>
                                                                    <MenuItem key={"templateOption"+ index} value={template}>{template}</MenuItem>
                                                                )}
                                                            </Select>
                                                        </GridItem>
                                                    }

                                                    <GridItem xs={1} sm={1} md={1}>
                                                        <Checkbox
                                                            color="primary"
                                                            onChange={(event) => setMonteCarloChecked(event.target.checked)}
                                                            checked={monteCarloChecked}
                                                            inputProps={{ 'aria-label': 'Enable monte carlo run' }}
                                                        />
                                                    </GridItem>

                                                    <GridItem xs={11} sm={11} md={11}>
                                                        <div style={{ marginTop: 10 }}>
                                                            <label>Enable Monte Carlo Run</label>
                                                        </div>
                                                    </GridItem>

                                                    <GridItem xs={1} sm={1} md={1}>
                                                        <Checkbox
                                                            color="primary"
                                                            onChange={(event) => setFullResultsChecked(event.target.checked)}
                                                            checked={fullResultsChecked}
                                                            inputProps={{ 'aria-label': 'Return full daycent results' }}
                                                        />
                                                    </GridItem>

                                                    <GridItem xs={11} sm={11} md={11}>
                                                        <div style={{ marginTop: 10 }}>
                                                            <label>Return full daycent results. Approx 512MB per run uncompressed.</label>
                                                        </div>
                                                    </GridItem>
                                                </GridContainer>
                                            }

                                            <GridItem xs={12} sm={12} md={12}>
                                                <h4 >Where would you like to send your run results?</h4>
                                            </GridItem>

                                            <GridItem xs={1} sm={1} md={1}>
                                                <div style={{ marginTop: 10 }}>
                                                    <Checkbox
                                                        color="primary"
                                                        onChange={handleUrlCheckChanged}
                                                        checked={urlChecked}
                                                        inputProps={{ 'aria-label': 'Send run results to call back url' }}
                                                    />
                                                </div>
                                            </GridItem>

                                            <GridItem xs={11} sm={11} md={11}>
                                                <CustomInput
                                                    labelText="Callback Url or Webhook"
                                                    inputProps={{ value: callbackUrl, onChange: (event) => handleCallbackUrlChanged(event) }}
                                                    id="url"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />
                                            </GridItem>

                                            <GridItem xs={1} sm={1} md={1}>
                                                <div style={{ marginTop: 10 }}>
                                                    <Checkbox
                                                        color="primary"
                                                        onChange={handleEmailCheckChanged}
                                                        checked={emailChecked}
                                                        inputProps={{ 'aria-label': 'Send run results to email' }}
                                                    />
                                                </div>
                                            </GridItem>
                                            <GridItem xs={11} sm={11} md={11}>
                                                <CustomInput
                                                    labelText="Email Address"
                                                    inputProps={{ value: email, onChange: (event) => handleEmailChanged(event) }}
                                                    id="email"
                                                    formControlProps={{
                                                        fullWidth: true
                                                    }}
                                                />
                                            </GridItem>

                                        </GridContainer>
                                    </GridItem>

                                </GridContainer>
                            </CardBody>

                        </Card>
                    </GridItem>
                    <GridContainer direction="row" justify="flex-start" alignItems="flex-start">

                        {/*Admin user's need to have all options available for upload. */}
                        {/*Non admin user's should only have access to APIs for which they've been granted access to.  These are set by admins. */}
                        {allModelTypes && allModelTypes.length > 0 && allModelTypes.map((model, index) =>
                            <div key={"uploadExampleIndex_" + index} >

                                {model.id === 1 &&
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Card >
                                            <CardHeader color={buttonColors[index]}>
                                                <h4 className={classes.cardTitleWhite}>{model.name} Examples</h4>
                                                <p className={classes.cardCategoryWhite}>Use the links below to download example input files</p>
                                            </CardHeader>
                                            <CardBody >
                                                <List className={classes.list}>
                                                    <ListItem button className={classes.itemLink}>
                                                        <Icon className={classNames(classes.itemIcon)}>
                                                            <Link to="/ggit-example.xml" target="_blank" download>
                                                                {"XML"}
                                                            </Link>
                                                        </Icon>
                                                    </ListItem>

                                                    <ListItem button className={classes.itemLink}>
                                                        <Icon className={classNames(classes.itemIcon)}>
                                                            <Link style={{ color: 'black' }} to="/ggit-example.json" target="_blank" download>
                                                                {"JSON"}
                                                            </Link>
                                                        </Icon>
                                                    </ListItem>
                                                </List>
                                            </CardBody>
                                            <CardFooter >
                                                <Button color={buttonColors[index]} onClick={() => { var win = window.open('https://documenter.getpostman.com/view/12489280/TW6uooz4', '_blank'); win.focus(); }} >
                                                    API Documentation
                                                </Button>
                                            </CardFooter>
                                        </Card>
                                    </GridItem>
                                }

                                {model.id === 2 && (currentUser.role === 'a' || hasAccessToApi(model.id)) &&//Admin users have access to all model, check other users
                                    <GridItem xs={12} sm={12} md={12}>
                                        <Card >
                                            <CardHeader color={buttonColors[0]}>
                                                <h4 className={classes.cardTitleWhite}>{model.name} Examples</h4>
                                                <p className={classes.cardCategoryWhite}>Use the links below to download example input files</p>
                                            </CardHeader>
                                            <CardBody >
                                                <List className={classes.list}>
                                                    <ListItem button className={classes.itemLink}>
                                                        <Icon className={classNames(classes.itemIcon)}>
                                                            <Link style={{ color: buttonColors[0] }} to="/daycent_cr_example_cropland_input.xml" target="_blank" download>
                                                                {"XML"}
                                                            </Link>
                                                        </Icon>
                                                    </ListItem>

                                                    <ListItem button className={classes.itemLink}>
                                                        <Icon className={classNames(classes.itemIcon)}>
                                                            <Link style={{ color: 'black' }} to="/daycent_cr_example_cropland_input.json" target="_blank" download>
                                                                {"JSON"}
                                                            </Link>
                                                        </Icon>
                                                    </ListItem>
                                                </List>

                                            </CardBody>
                                            <CardFooter >
                                                <Button color={buttonColors[0]} onClick={() => { var win = window.open('https://documenter.getpostman.com/view/12489280/TVep87Kd', '_blank'); win.focus(); }} >
                                                    API Documentation
                                                </Button>
                                            </CardFooter>
                                        </Card>
                                    </GridItem>
                                }

                            </div>
                        )}

                    </GridContainer>
                </GridContainer>

                <DynamicDialog
                    buttons={dialogKeepMounted ? null : dialogButtons}
                    dialogKeepMounted={dialogKeepMounted}
                    open={showDialog}
                    labelId={"uploadDialog"}
                    title={dialogTitle}
                    textMessage={dialogMessage}
                />
            </div>
        );
    }
