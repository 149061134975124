import { createReducer } from 'redux-act';
import * as a from '../actions/user';
import { takeIfExists } from '../utils/localStorage';


const getDefaultState = _ => ({
  id: takeIfExists('id'),
  last: '',
  email: '',
  first: '',
  confirmed: false,
  role: 'b',
  pristine: true,
  subscribed: false,
  opt: false,
  terms: false
})

export default _ =>
  createReducer(
    {
      [a.updateUser]: (state, { id, last, email, company, first, confirmed, role, pristine, companies, subscribed, opt, terms }) => ({
        ...state,
        id,
        last,    
        email,    
        company,
        first,
        confirmed,
        role,
        pristine,
        companies,
        subscribed, 
        opt,
        terms      
      }),
      [a.receiveUser]: (state, { id, last, email, company, first, confirmed, role, pristine, companies, subscribed, opt, terms  }) => ({
        ...state,
        id,
        last,    
        email,    
        company,
        first,
        confirmed,
        role,
        pristine,
        companies,
        subscribed,
        opt,
        terms          
      })

    },
    getDefaultState()
)