import React, { useEffect, useState, useCallback } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import TableGrid from "../../components/Table/TableGrid.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import { useSelector, useDispatch } from 'react-redux'
import { updateLoading } from "../../actions/mask";
import { LEDGER_FETCH_RUNS, LEDGER_DOWNLOAD, LEDGER_USER_SUMMARY, LEDGER_USER_FILE_REPORTS } from "../../constants/api";
import { saveAs } from 'file-saver';
import DynamicDialog from "../../components/Dialogs/Dialog.js";
import Button from "../../components/CustomButtons/Button.js";
import * as helpers from "../../helpers/helpers";
import {ledgerColumns, ledgerSort } from "../../helpers/gridHelper";
import { headerWithAuth } from "../../utils/auth";
import moment from "moment";
import CustomDatePicker from "../../components/CustomDatePicker/CustomDatePicker"


const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};


const useStyles = makeStyles(styles);

export default function Ledger() {
  const classes = useStyles();
  const [runs, setRuns] = React.useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [startDate, setStartDate] = useState( moment.utc());
  const [endDate, setEndDate] = useState( moment.utc() );
  const [dialogMessage, setDialogMessage] = React.useState('Would you like to download the selected records?');
  const [showDialog, setDialog] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState('Download ledger');
  const [dialogKey, setDialogKey] = React.useState('n'); //This is to help switch on which buttons to display in dialog.  Values can be n - normal message, h - history, q - queue, r - running
  const currentUser = useSelector(state => state.user);

  const dispatch = useDispatch();

  const fetchLedger = useCallback((showMask) => {

    if (firstLoad || showMask)//We only want to show mask for first load since this is a periodic fetch
      dispatch((updateLoading({ loading: true })));

    var data = {
      UserId: currentUser.id,
      StartDate: startDate,
      EndDate: endDate,
      UserIds: []
    }


    fetch(LEDGER_FETCH_RUNS, {
      method: "POST", body: JSON.stringify(data),
      headers: headerWithAuth()
    }).then(response => {
      if (response.status === 200) //Ok may have json data may not.
      {
        return response.json() // return the result of the inner promise, which is an error
          .then((json) => {
           // console.log(json);
           setRuns(json);

            setFirstLoad(false);//Set first load to false after we know this has been called once.
            dispatch((updateLoading({ loading: false })));

            return null;
          });
      }

      else //Some error before uploading the files
      {
        setFirstLoad(false);//Set first load to false after we know this has been called once.
        dispatch((updateLoading({ loading: false })));
      }
    })
      .catch((error) => {
        console.error('Error:', error);
        setFirstLoad(false);//Set first load to false after we know this has been called once.
        dispatch((updateLoading(false)));
      });
  }, [currentUser.id, dispatch, endDate, firstLoad, startDate]);

    const userGrouping = () => {

        dispatch(updateLoading({ loading: true }));

        let partialOrFinal = "Final";
        let endOfMonth = moment(endDate).endOf('month');

        if (moment().diff(endOfMonth, 'days')) {
            partialOrFinal = "Partial";
        }
        var fileName = partialOrFinal + '_User_Report_Files_' + startDate.format("MMMM_YYYY") + "_" + endDate.format("MMMM_YYYY") + ".zip";



        var data = {
            UserId: currentUser.id,
            StartDate: startDate,
            EndDate: endDate,
            UserIds: []
        }

        fetch(LEDGER_USER_FILE_REPORTS, {
            method: "POST", body: JSON.stringify(data),
            headers: headerWithAuth()
        }).then(response => {
            if (response.status === 200) //Ok may have json data may not.
            {
                console.log(response)
                return response.blob() // return the result of the inner promise, which is an error
                    .then((res) => {
                        console.log(res)
                        const blob = new Blob([res], {
                            type: 'application/octet-stream'
                        });

                        saveAs(blob, fileName);
                        dispatch((updateLoading({ loading: false })));
                    })
            }

            else { //Response was not 200
                updateDialog('Download Failed', 'Response status : ' + response.status, true);
                dispatch((updateLoading({ loading: false })));
            }

        })
            .catch((error) => {
                console.error('Error:', error);
                updateDialog('Download Failed', error, true);
                dispatch((updateLoading({ loading: false })));
            });


    }


    const userSummary = () => {

        dispatch(updateLoading({ loading: true }));

        let partialOrFinal = "Final";
        let endOfMonth = moment(endDate).endOf('month');

        if (moment().diff(endOfMonth, 'days')) {
            partialOrFinal = "Partial";
        }
        var fileName = partialOrFinal + '_User_Summary_Ledger_' + startDate.format("MMMM_YYYY") + "_" + endDate.format("MMMM_YYYY") + ".csv";



        var data = {
            UserId: currentUser.id,
            StartDate: startDate,
            EndDate: endDate,
            UserIds: []
        }

        fetch(LEDGER_USER_SUMMARY, {
            method: "POST", body: JSON.stringify(data),
            headers: headerWithAuth()
        }).then(response => {
            if (response.status === 200) //Ok may have json data may not.
            {

                return response.blob() // return the result of the inner promise, which is an error
                    .then((res) => {

                        const blob = new Blob([res], {
                            type: 'application/octet-stream'
                        });

                        saveAs(blob, fileName);
                        dispatch((updateLoading({ loading: false })));
                    })
            }

            else { //Response was not 200
                updateDialog('Download Failed', 'Response status : ' + response.status, true);
                dispatch((updateLoading({ loading: false })));
            }

        })
            .catch((error) => {
                console.error('Error:', error);
                updateDialog('Download Failed', error, true);
                dispatch((updateLoading({ loading: false })));
            });


    }

  const downloadLedger = () => {
    dispatch( updateLoading({ loading: true }) );

    var fileName = 'ledger_' + startDate.format() + "_" + endDate.format() + ".csv";

    var data = {
      UserId: currentUser.id,
      StartDate: startDate,
      EndDate: endDate,
      UserIds: []
    }

      fetch(LEDGER_DOWNLOAD, {
        method: "POST", body: JSON.stringify(data),
        headers: headerWithAuth()
      }).then(response => {
        if (response.status === 200) //Ok may have json data may not.
        {

          return response.blob() // return the result of the inner promise, which is an error
            .then((res) => {
              // console.log(res);
              const blob = new Blob([res], {
                type: 'application/octet-stream'
              });

              saveAs(blob, fileName);
              dispatch((updateLoading({ loading: false })));
            })
        }

        else { //Response was not 200
          updateDialog('Download Failed', 'Response status : ' + response.status, true);
          dispatch((updateLoading({ loading: false })));
        }

      })
        .catch((error) => {
          console.error('Error:', error);
          updateDialog('Download Failed', error, true);
          dispatch((updateLoading({ loading: false })));
        });


    }

    const updateDialog = (title, message, show) => {
      setDialogTitle(title)
      setDialogMessage(message);
      setDialog(show);
    }

  const resetDialogs = () => {
    setDialog(false);
    setDialogKey('n');
  }

  var cancelButton = helpers.getDialogButton("Cancel", resetDialogs);
  var okButton = helpers.getDialogButton("Ok", resetDialogs);
  var confirmDownloadButton = helpers.getDialogButton("Download", downloadLedger);

  var normalDialogButtons = [okButton];
  var downloadDialogButtons = [cancelButton, confirmDownloadButton];

  //Just switching on buttons here.  Different buttons need to trigger specific functions.
  var dialogButtons = {
    n: normalDialogButtons,
    h: downloadDialogButtons
  }

  /*Set the selected users as this user. By default.*/
  useEffect(() => {

    // console.log("first load: ", firstLoad);
    // console.log("current user: ", currentUser);

    if (firstLoad && helpers.userReady(currentUser))
    {
      setFirstLoad(false);
      fetchLedger(true);
      // console.log('calling from first load');
    }

  }, [firstLoad, currentUser, setFirstLoad, fetchLedger]);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="info" >
              <GridContainer direction='row' alignItems="flex-start">
                <GridItem xs={12} sm={12} md={3}>
                  <h4 className={classes.cardTitleWhite}> All Runs </h4>
                  <p className={classes.cardCategoryWhite}>Run Ledger</p>

                </GridItem>
                <GridItem xs={12} sm={12} md={9}>
                  <GridContainer direction="row" backgr alignItems="flex-start" >
                    <GridItem xs={12} sm={12} md={12}>
                      <GridContainer direction="row" justify="space-between" alignItems="flex-start">
                        <GridItem xs={12} sm={12} md={9}>
                          <GridContainer justify="space-around">
                            <CustomDatePicker
                            dateValue={startDate._d}
                            label="Start Date"
                            minDate={startDate._d}
                            onChange={setStartDate}
                          />
                          <CustomDatePicker
                            dateValue={endDate._d}
                            label="End Date"
                            minDate={startDate._d}
                            onChange={setEndDate}
                          />
                          </GridContainer>
                        </GridItem>
                        <GridItem xs={12} sm={4} md={3}>

                          <GridContainer alignItems="flex-end" >
                            <GridItem xs={12}>
                              <Button color={"success"} onClick={() => downloadLedger(true)} size="sm">Download</Button>
                                                      </GridItem>
                                                      {currentUser.role === "a" &&
                                                          <GridItem xs={12}>
                                                          <Button color={"warning"} onClick={() => userSummary(true)} size="sm">User Summary</Button>

                                                           <Button color={"warning"} onClick={() => userGrouping(true)} size="sm">Individual User Reports</Button>
                                                            </GridItem>

}
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                    </GridItem>
                  </GridContainer>

                  </GridItem>
                {/* <Button color={"success"} onClick={() => downloadLedger(true)} size="sm">Download Ledger</Button> */}

              </GridContainer>
                      </CardHeader>

            <CardBody>
              <TableGrid
                tableHeaderColor="primary"
                rows={runs}
                size={100}
                columns={ledgerColumns}
                sortModel={ledgerSort}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <DynamicDialog
        buttons={dialogButtons[dialogKey]}
        open={showDialog}
        labelId={"historyDialog"}
        title={dialogTitle}
        textMessage={dialogMessage}
      />
    </div>
  );
}
