import { put, call } from "redux-saga/effects";
import { SubmissionError } from "redux-form";

import { to } from "../actions/navigation";
import { receiveAuthData } from "../actions/auth";
import {
  LOGIN,
  LOGINCOMET,
  REGISTER,
  RESET,
  VERIFY,
    RESET_PASSWORD,
    RESEND_CONFIRMATION
} from "../constants/api";
import { post } from "../utils/api";
import { updateLoading } from "../actions/mask";
import { startApp } from "../actions/generic";
import { login } from "../utils/auth";
import { fetchUser, receiveUser } from "../actions/user";


const authSaga = (url, thanGoTo) =>
  function*({ payload: { values, reject } }) {
    try {

      yield put(updateLoading({ loading: true }));
      const apiToken = yield call(post, url, values);
      //console.log(apiToken);
      yield put(receiveAuthData({token:apiToken.jwtToken, tokenExpirationTime:apiToken.tokenExpirationTime, id:apiToken.id}));
      yield put(startApp());
      // console.log(thanGoTo);
      yield put(to(thanGoTo));

      yield call(login, {token:apiToken.jwtToken, tokenExpirationTime:apiToken.tokenExpirationTime, id:apiToken.id} );
      yield put(fetchUser({ Id: apiToken.id }));
      yield put(updateLoading({ loading: false }));

    } catch ({ status, message }) {
      //console.log(message);
      if(message)
      yield call(reject, new SubmissionError( message.errors? message.errors : message ));

      yield put(updateLoading({ loading: false }));
    }
  };

const authSagaNoUpdate = (url, thanGoTo) =>
  function*({ payload: { values, reject } }) {
    try {
      yield put(updateLoading({ loading: true }));
      yield put(to(thanGoTo));
      yield put(updateLoading({ loading: false }));
    } catch ({ status, message }) {
      yield call(reject, new SubmissionError(message));
      yield put(updateLoading({ loading: false }));
    }
  };

export function* submitVerify(action) {
  try {
    yield put(updateLoading({ loading: true }));
    const data = yield call(post, VERIFY, action.payload);

    yield put(to("showVerifyConfirmed"));
    yield put(receiveUser(data));
    yield put(updateLoading({ loading: false }));
  } catch (error) {
    yield put(to("showError"));
    yield put({ type: "FETCH_FAILED", error });
    yield put(updateLoading({ loading: false }));
  }
}

export const submitReset = authSaga(RESET, "showResetConfirmed");
export const submitChangePassword = authSaga(
  RESET_PASSWORD,
  "showChangePasswordConfirmed"
);

export const submitResend = authSaga(RESEND_CONFIRMATION, "showResendConfirmed");

export const submitConfirmAccount = authSaga(VERIFY, "confirmedConfirmed");

export const submitLogin = authSaga(LOGIN, "showLoginConfirmed");
export const submitLoginComet = authSaga(LOGINCOMET, "showLoginConfirmed");
export const submitRegister = authSaga(REGISTER, "showRegisterConfirmed");
export const submitAdminRegister = authSagaNoUpdate(
  REGISTER,
  "showAddUserSuccess"
);
