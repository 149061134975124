import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from 'react-redux';
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Navbar from "components/Navbars/Navbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import styles from "assets/jss/material-dashboard-react/layouts/userStyle.js";
import bgImage from "assets/img/sidebar-1.jpg";
import whiteListBgImage from "assets/img/sidebar-1-2.jpg";
import { FRONTEND } from '../constants/api'
import logo from "assets/img/reactlogo.png";
import routes from "../routes.js";

const useStyles = makeStyles(styles);
let ps;

export default function User({ ...rest }) {
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const currentUser = useSelector(state => state.user);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };

  const switchRoutes = () => {
    let role = currentUser.role;

    let userRoutes = routes.filter(r => ( (r.admin === false) || (role === 'a' ) ) );

    return <Switch>
      {userRoutes.map((prop, key) => {
        if (prop.layout === "/user") {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}
      <Redirect from="/user" to="/user/upload" />
    </Switch>

}

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    window.addEventListener("resize", resizeFunction);
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
      window.removeEventListener("resize", resizeFunction);
    };
  }, [mainPanel]);

  let role = currentUser.role;
  let filteredRoutes = routes.filter(r => ( (r.admin === false) || (role === 'a' ) ) );

  return (
    <div className={classes.wrapper}>
      {/* This is the main sidebar.  Has static bar and mobile burger bar.*/}
      <Sidebar
        routes={filteredRoutes}
        logoText={FRONTEND !== "on" ? "Soil Metrics API" : "GHG inventory API"}
        logo={logo}
        image={FRONTEND !== "on" ? bgImage : whiteListBgImage}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color={"blue"}
        {...rest}
      />
      <div className={classes.mainPanel} ref={mainPanel}>
        {/* This is the floating sidebar*/}
        <Navbar
          routes={filteredRoutes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        {/* On the /maps route we want the map to be on full screen - this is not possible if the content and container classes are present because they have some paddings which would make the map smaller */}
        {/*Renders the component based on the url.  We do this via the routes defined above. */}
        {
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes()}</div>
          </div>
        }

        {/*<Footer />*/}


      </div>
    </div>
  );
}