export const loggedIn = () => {
  const expirationTime = localStorage.getItem("tokenExpirationTime");
  return new Date().getTime() / 1000 < parseInt(expirationTime, 10);
};

export const login = ({token, tokenExpirationTime, id}) => {
  
  localStorage.setItem("token", token);
  localStorage.setItem("tokenExpirationTime", tokenExpirationTime);
  localStorage.setItem("id", id);
};

export const unlogin = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("tokenExpirationTime");
};


export const storeCompany = (compId,Time) => {
  localStorage.setItem("ClaimCompany",compId+"&&"+Time);
  console.log('storing',localStorage)
};

export const peekCompany = () => {
  try{
   const storedCompany= localStorage.getItem("ClaimCompany").split("&&");
   console.log('peeking',storedCompany)
   console.log(new Date() - new Date(storedCompany[1]),new Date(storedCompany[1]))
 
   if(new Date() - new Date(storedCompany[1]) < 600000){
     console.log('returning',storedCompany[0] )
     return storedCompany[0];
   }
   else{
     return 0;
   }
  }
catch(e){
  console.log('returning default',0 )
return 0;
}
  
};

export const fetchCompany = () => {
  try{
    console.log('doing clear fetch')
    const storedCompany= localStorage.getItem("ClaimCompany").split("&&")
   localStorage.removeItem("ClaimCompany");

   console.log('local storage is',localStorage)
   if(new Date() -  new Date(storedCompany[1]) < 600000){
     return storedCompany[0];
   }
   else{
    return 0;
  }
  }
  catch(e){
return 0;
}
  
};

export const headerWithAuth = () => 
{

  //return 'fakeTok';
  return {'Content-Type': 'application/json', 'Authorization': 'Bearer ' + localStorage.getItem("token")};

};

export const headerOnlyAuth = () => 
{
  //return 'fakeTok';
  return {'Authorization': 'Bearer ' + localStorage.getItem("token")};

};