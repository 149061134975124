import _ from "lodash";
import { clone, setWith, curry } from "lodash/fp";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import momentZone from 'moment-timezone';
const userTZ = momentZone.tz.guess();

export const connectTo = (mapStateToProps, actions, Container) => {
  const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch);
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Container);
};

export const callIf = (condition, func) => (condition ? func : _ => null);

export const getUniqueName = (name, otherNames) => {
  const suffixes = otherNames
    .filter(n => n.startsWith(name))
    .map(n => n.slice(name.length));
  if (!suffixes.includes("")) return name;

  let number = 1;
  while (number) {
    const strNumber = number.toString();
    if (!suffixes.includes(strNumber)) return name + strNumber;
    number++;
  }
};

export const takeFromState = (state, stateObjectName, fields) =>
  _.pick(state[stateObjectName], fields);

export const setIn = curry((obj, path, value) =>
  setWith(clone, path, value, clone(obj))
);

export const firstUpperWords = (text, length) =>
  text
    .split(" ")
    .map(element => element[0].toUpperCase())
    .splice(0, length);

export const sliceWithDots = (text, length) => {
  return text.length > length ? text.slice(0, length) + "..." : text;
};

export const pluralize = (text, amount) => {
  return amount === 1 ? text : text + "s";
};

export const pascalToText = text =>
  _.capitalize(text.replace(/([A-Z][a-z])/g, " $1").replace(/(\d)/g, " $1"));

export const snakeToText = text => _.capitalize(text.split("_").join(" "));
export const def = v => v === undefined;

export const logArgs = func => (...args) => {
  console.info(`${func.name} args: `, ...args);
  return func(...args);
};

export const getStaticAdUrl = (ad) =>{



  return ad.city+'/'+ad.state+'/'+ad.company.replace(/\s/g, "-");;

}

/*Date coming in should be in utc*/
export const getDateString = (date) =>
{
  let dt = moment.utc(date);
  let dtz = moment.tz(dt, userTZ);
  let dateS = "Invalid Date";

  if (dtz)
    dateS = `${(dtz.month() + 1).toString().padStart(2, '0')}/${dtz.date().toString().padStart(2, '0')}/${dtz.year().toString().padStart(4, '0')} ${dtz.hour().toString().padStart(2, '0')}:${dtz.minute().toString().padStart(2, '0')}:${dtz.seconds().toString().padStart(2, '0')} ${dtz.zoneAbbr()}`;

  return dateS;
}


export const getDistance = (lat1, lon1, lat2, lon2) => {
  var R = 3958.8; // radius of earth in miles
  var dLat = deg2rad(lat2 - lat1); // deg2rad below
  var dLon = deg2rad(lon2 - lon1);
  var a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = R * c; // Distance in mi

  return d;
};

const deg2rad = deg => {
  return deg * (Math.PI / 180);
};

export const getRenewalDate = (annualOrMonthly, date) => {
  var d = new Date(date);
  d.setFullYear(d.getFullYear() + 1); //Adding one year for expiration/renewal date

  if (annualOrMonthly === "m") d.setMonth(d.getMonth() + 1); //Adding one year for expiration/renewal date

  return d.toDateString();
};

export const getStatusText = (code) =>
{
  let fullStatus = code;

  if (code === 'r')
    fullStatus = 'Running';

  if (code === 'f')
    fullStatus = 'Failed';

  if (code === 's')
    fullStatus = 'Started';

  if (code === 'sr')
    fullStatus = 'Started';

    if (code === 'sw')
    fullStatus = 'Started';

    if (code === 'sd')
    fullStatus = 'Run Disappeared';

  if (code === 'fs')
    fullStatus = 'Finished';

  if (code === 'fsd')
    fullStatus = 'Finished Report Delivered';

    if (code === 'ef')
    fullStatus = 'Format Error';

  if (code === 'fe')
    fullStatus = 'Finished With Errors';

    if (code === 'fe3')
    fullStatus = 'Failed 3 Times';

  if (code === 'fe3d')
    fullStatus = 'Failed 3 Times';

  if (code === 'fe3e')
    fullStatus = 'Finished With Errors';

  if (code === 'n')
    fullStatus = 'Claimed Not Started';

    if (code === 'ud')
    fullStatus = 'User Deleted Run';

  return fullStatus;
}
