import React from 'react';
import { Field, reduxForm } from 'redux-form';
import GeneralTextField from "../TextFields/GeneralTextField";
import Button from "../CustomButtons/Button";
import GeneralCheck from "../CheckBoxes/GeneralCheck";
import Copyright from "../Copyright";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { submitAsyncValidation } from "../../utils/forms";
import { useHistory } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import {
    validate,
    warn
  } from "../../validators/forms";
  import { FRONTEND } from 'constants/api';

const RegisterForm = props => {
  const { handleSubmit, submitting } = props
  let history = useHistory();
  return (
    <form onSubmit={submitAsyncValidation(
      handleSubmit,
      true,
      props.onSubmit
    )}>

            <Field
              name="email"
              id="email"
              component={GeneralTextField}
              label="Email Address"
              type="text"
              autoComplete="email"
            />

            <Field
              name="first"
              id="first"
              component={GeneralTextField}
              label="First Name"
              type="text"
              autoComplete="first"
            />

            <Field
              name="last"
              id="last"
              component={GeneralTextField}
              label="Last Name"
              type="text"
              autoComplete="last"
            />

            <Field
              name="company"
              id="company"
              component={GeneralTextField}
              label="Company"
              type="text"
              autoComplete="company"
            />
        
              <Field
                name="password"
                id="password"
                component={GeneralTextField}
                label="Password"
                autoComplete="current-password"
                type={ "password"}
              />

                <Field
                name="confirmpassword"
                id="confirmpassword"
                component={GeneralTextField}
                label="Confirm Password"
                autoComplete="current-password"
                type={ "password"}
                />           

            <div className="flex-wrapper-row-space">
            <div >
            <Typography component="h1" variant="h5">
                I ACCEPT the{" "}
                <Link
                  to={"/terms"}
                  target="_blank"
                  style={{ textDecoration: "underline" }}
                >
                  Terms and Conditions
                </Link>
                </Typography>
                </div>

              <div >
              <Field 
              name="terms" 
              id="terms"
              required={true}
              component={GeneralCheck} 
              type="checkbox"/>
              </div>
            </div>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="submit"
              disabled={submitting}
            >
              Create Account
            </Button>

            <Grid container>
              <Grid item xs>
                <Link onClick={() => history.push('/login') } variant="body2">
                  Already have an account?
                </Link>
              </Grid>
              <Grid item>
                <Link onClick={() => history.push('/login') } variant="body2">
                  {"Login"}
                </Link>
              </Grid>
            </Grid>
            {FRONTEND !== "on" && <Box mt={5}>
              <Copyright />
            </Box>}
    </form>
  )
}

export default reduxForm({
  form: 'registerForm',
  validate,
  warn
})(RegisterForm)