import React, { useEffect, useState } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import CardFooter from "../../components/Card/CardFooter.js";
import { useSelector, useDispatch } from "react-redux";
import "./UserProfile.css";
import { updateUser } from "../../actions/user";
import DynamicDialog from "../../components/Dialogs/Dialog.js";
import * as helpers from "../../helpers/helpers.js";
import { to } from "../../actions/navigation.js";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0",
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
  },
};

const useStyles = makeStyles(styles);

export default function UserProfile() {
  const classes = useStyles();
  const user = useSelector((state) => state.user);
  const navigation = useSelector(state => state.navigation)
  const dispatch = useDispatch();

  const [state, setState] = useState({
    first: user.first || "",
    last: user.last || "",
    company: user.company || "",
    password: "",
    confirmPassword: "",
    id: user.id
  });
  const [updateError, setUpdateError] = React.useState(false);
  const [updateSuccess, setUpdateSuccess] = React.useState(false);
  const [showDialog, setDialog] = React.useState(false);
  const [dialogMessage, setDialogMessage] = React.useState("");
  const [dialogKeepMounted, setDialogMount] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState("");

  useEffect( ()=> {
    if(navigation.page === "showUpdateUserSuccess") {
      setUpdateSuccess(true);
      updateSuccess && updateDialog('Updating Account', 'Your account has been updated.', false, true);
    }

    if(navigation.page === "showUpdateError") {
      setUpdateError(true);
      updateError && updateDialog('Updating Account Failed', 'The server encountered an error while uploading your account info. Please try again later.', false, true);
    }

  }, [navigation.page, updateError, updateSuccess]);


  const updateDialog = (title, message, mount, show) =>
  {
    setDialogTitle(title)
    setDialogMessage(message);
    setDialogMount(mount)
    setDialog(show);
  }

  const resetDialogs = () => {
    setUpdateSuccess(false);
    setUpdateError(false);
    setDialog(false);
    dispatch(to(""));
  };

  var okButton = helpers.getDialogButton("Ok", resetDialogs);
  var dialogButtons = [okButton];

  const handleChange = (event) => {
    const { id, value } = event.target;
    setState(prevState => ({ ...prevState, [id]: value }));
  };

  // const userAttributesOrder = [
  //   // {
  //   //   currentValue: user.name,
  //   //   label: "USERNAME",
  //   //   allowChange: false,
  //   // },
  //   // {
  //   //   currentValue: user.email,
  //   //   label: "email",
  //   //   allowChange: false,
  //   // },
  //   {
  //     currentValue: user.role,
  //     label: "role",
  //     allowChange: false,
  //   },
  //   {
  //     currentValue: first,
  //     label: "first name",
  //     //allowChange: true,
  //   },
  //   {
  //     currentValue: last,
  //     label: "last name",
  //     //allowChange: true,
  //   },
  //   {
  //     currentValue: company,
  //     label: "company",
  //     //allowChange: true,
  //   },
  //   {
  //     label: "password",
  //     //allowChange: true,
  //     //type: "password",
  //     currentValue: password,
  //   },
  //   {
  //     label: "confirm password",
  //     //allowChange: true,
  //     //type: "password",
  //     currentValue: company,
  //   },
  // ];

  const handleUpdateProfile = event => {
    dispatch(updateUser(state));
    // updateSuccess &&  updateDialog('Updating Account', 'Your account has been updated.', false, updateSuccess);
    // updateError && updateDialog('Updating Account Failed', 'The server encountered an error while uploading your account info. Please try again later.', false, updateError);
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <h4 className={classes.cardTitleWhite}>
                Update Profile: {user.email}
              </h4>
              <p className={classes.cardCategoryWhite}>
                Edit Your Account Details
              </p>
            </CardHeader>
            <CardBody>
              <GridContainer>
                <CustomInput id="first" labelText={state.first || "First Name"} inputProps={{ value: state.first, onChange: (event) => handleChange(event) }} formControlProps={{fullWidth: true}} />
                <CustomInput id="last" labelText={state.last || "Last Name"} inputProps={{ value: state.last, onChange: (event) => handleChange(event) }} formControlProps={{fullWidth: true}} />
                <CustomInput id="company" labelText={state.company || "Company"} inputProps={{ value: state.company, onChange: (event) => handleChange(event) }} formControlProps={{fullWidth: true}} />
                <CustomInput id="password" labelText={state.password || "Password"} inputProps={{ value: state.password, onChange: (event) => handleChange(event) }} formControlProps={{fullWidth: true}} />
                <CustomInput id= "confirmPassword" labelText={state.confirmPassword || "Confirm Password"} inputProps={{ value: state.confirmPassword, onChange: (event) => handleChange(event) }} formControlProps={{fullWidth: true}} />
                {/* {userAttributesOrder.map((attr, index) => (
                  <UserAttribute {...attr} key={"userAttribute" + index} />

                ))} */}
              </GridContainer>
            </CardBody>
            <CardFooter>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Button onClick={handleUpdateProfile} color="primary">update profile</Button>
                  <Button color="danger">delete profile</Button>
                </GridItem>
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <DynamicDialog
        buttons={dialogKeepMounted ? null : dialogButtons}
        dialogKeepMounted={dialogKeepMounted}
        open={showDialog}
        labelId={"updateAccountDialog"}
        title={dialogTitle}
        textMessage={dialogMessage}
      />
    </div>
  );
}
