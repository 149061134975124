import { createAction } from "redux-act";

export const submitLogin = createAction();
export const submitLoginComet = createAction();
export const submitRegister = createAction();
export const contactUs = createAction();
export const submitReset = createAction();
export const receiveAuthData = createAction();
export const unauthorizeUser = createAction();
export const submitAdminRegister = createAction();
export const submitChangePassword = createAction();
export const submitVerify = createAction();
export const submitPayment = createAction();
export const submitResend = createAction();
export const submitConfirmAccount = createAction();