import React, {useEffect, useState } from "react";
import Typography from '@material-ui/core/Typography';
import Checkbox from "@material-ui/core/Checkbox";
import GridItem from "../Grid/GridItem.js";
import GridContainer from "../Grid/GridContainer.js";

/*Not going to use redux forms.  Just going to have controls and track the state.
Drawback here is we will need to manually update items and manually send updated items back to parent.*/
export default function EditUserForm(props) {

  const { userGgitChecked, userCrChecked, userGgit30Checked, userAdmin, userUpdateGgit, userUpdateCr, userUpdateGgit30 } = props

  const [ggitChecked, setGgit] = useState(false);
  const [ggit30Checked, setGgit30] = useState(false);
  const [crChecked, setCr] = useState(false);

  /*Set initial values from user object.  Wait until user values are not null, first load may have null values. */
  useEffect(() => {

      setGgit( userGgitChecked );
      setGgit30( userGgit30Checked );
      setCr(userCrChecked);
  }, [userGgitChecked, userCrChecked, userGgit30Checked, userAdmin ]);

  const updateCr = (e) => {

  userUpdateCr(e.target.checked);
  setCr(e.target.checked);

  }


  const updateGgit = (e) => {

    userUpdateGgit(e.target.checked);
    setGgit( e.target.checked );
  }

  const updateGgit30 = (e) => {
  
    userUpdateGgit30(e.target.checked);
    setGgit30( e.target.checked );
  }

  return (
    <div style={{marginTop:10}}>
      <GridContainer direction='column' justify="flex-start" alignItems="flex-start">
        <GridItem xs={12} sm={12} md={12}>
          <div className="flex-wrapper-row-space">
            <div >
              <Typography component="h1" variant="h6">
                Check Box to grant access to Green House Gas Inventory Tool
              </Typography>
            </div>

            <div >
              <Checkbox
                color="primary"
                checked={ggitChecked}
                onChange={updateGgit}
              />
            </div>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
        <div className="flex-wrapper-row-space">
            <div >
              <Typography component="h3" variant="h6">
                Check the box below to grant the user access to Daycent CR
              </Typography>
            </div>

            <div >
              <Checkbox
                color="primary"
                checked={crChecked}
                onChange={updateCr}
              />
            </div>
          </div>

        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <div className="flex-wrapper-row-space">
            <div >
              <Typography component="h1" variant="h6">
                Check Box to grant access to 30cm Green House Gas Inventory Tool
              </Typography>
            </div>

            <div >
              <Checkbox
                color="primary"
                checked={ggit30Checked}
                onChange={updateGgit30}
              />
            </div>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
        <div className="flex-wrapper-row-space">
            <div >
              <Typography component="h3" variant="h6">
                To grant admin role to user, please contact technical admin.
              </Typography>
            </div>

            {/* <div >
              <Checkbox
                color="primary"
                checked={adminChecked}
                disabled
              />
            </div> */}
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
}
