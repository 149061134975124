import React from "react";
// @material-ui/core components
import { XGrid } from '@material-ui/x-grid';


export default function CustomGrid(props) {
  const { rows, columns, apiRef, sortModel, size } = props;
  // console.log(rows);
  return (
    <div style={{ height: props.height ? props.height : 800, width: '100%' }}>
        <XGrid rows={rows}
        columns={columns}
        apiRef={apiRef}
        pageSize={size ? size : 5}
        pagination
        rowsPerPageOptions={[5, 10, 20]}
        autoPageSize={props.autoPageSize}
        checkboxSelection={props.checkboxSelection}
        onRowClick={props.onRowClick ? props.onRowClick : null}
        sortModel={sortModel} />
    </div>
  );
}
