import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import staticImage from "../assets/img/loading.gif";


const styles = theme => ({
  root: {
    width: "100%",
    height: "100%",
    position: "absolute",
    textAlign: "center",
    paddingTop: "20%",
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    zIndex: 999999
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
});

class LoadingMask extends React.Component {
  state = {
    show: false,
  };
  
  componentDidMount(){
    this.setState({show:true});
  }

  handleClose = () => {
    this.setState({show:false});
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
          <img
                    style={{ height: "100px" }}
                    src={staticImage}
                    alt={"Loading"}
                  />
                  <h2>{this.props.message ? this.props.message : "Loading..."}</h2>
                

      </div>
    );
  }
}

LoadingMask.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(LoadingMask);