import React from 'react';
import { Field, reduxForm } from 'redux-form';
import GeneralTextField from "../TextFields/GeneralTextField";
import { connect } from 'react-redux'
import Button from "../CustomButtons/Button";
import Copyright from "../Copyright";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { submitAsyncValidation } from "../../utils/forms";
import { useHistory } from "react-router-dom";
import {
    validate,
    warn
} from "../../validators/forms";
import { FRONTEND } from 'constants/api';

const ChangePasswordUnauthedForm = props => {
    const { handleSubmit, submitting } = props
    let history = useHistory();


    return (
        <form onSubmit={submitAsyncValidation(
            handleSubmit,
            true,
            props.onSubmit
        )}>

            <div style={{ display: 'none' }}>
            <Field

                name="email"
                id="email"
                component={GeneralTextField}
                label="Email Address"
                type="text"
                autoComplete="email"
            />

            <Field

                name="code"
                id="code"
                component={GeneralTextField}
                label="Confirmation Code"
                type="text"
                autoComplete="confirmation-code"
            />
                </div>
            <Field
                name="password"
                id="password"
                component={GeneralTextField}
                label="New Password"
                autoComplete="new-password"
                type={"password"}
            />

            <Field
                name="confirmpassword"
                id="confirmpassword"
                component={GeneralTextField}
                label="Confirm Password"
                autoComplete="new-password"
                type={"password"}
            />



            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className="submit"
                disabled={submitting}
            >
                Change Password
            </Button>

            <Grid container>

                <Grid item>
                    <Link onClick={() => history.push('/login')} variant="body2">
                        {"Login"}
                    </Link>
                </Grid>
            </Grid>
            {FRONTEND !== "on" && <Box mt={5}>
                <Copyright />
            </Box>}
        </form>
    )
}

let changeauth=reduxForm({
    form: 'changePasswordUnauthedForm',
    validate,
    warn,

})(ChangePasswordUnauthedForm)

const mapStateToProps = (state, props) => ({
    initialValues: props.defaultValue,
})

export default connect(mapStateToProps)(changeauth)


