import React from "react";
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import LoginForm from "../components/Forms/LoginForm";
import { useDispatch } from "react-redux";
import { submitLogin } from "../actions/auth";
// core components
import styles from "assets/jss/material-dashboard-react/layouts/loginStyle.js";


const useStyles = makeStyles(styles);


export default function Login({ ...rest }) 
{ 
  const dispatch = useDispatch();
  // styles
  const classes = useStyles();

  const submit = props => {
    //console.log(props);
    dispatch((submitLogin(props)));
  }

  return (
      <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <LoginForm onSubmit={submit} />
        </div>
      </Grid>
    </Grid>
  );
}
