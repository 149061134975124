import React from 'react';
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { loggedIn } from "./utils/auth";
// core components
import User from "pages/User.js";
import Login from "pages/Login.js";
import Register from 'pages/Register';
import GenericMessagePage from 'pages/GenericMessagePage'
import Forgot from 'pages/Forgot';
import Resend from 'pages/Resend';
import Confirm from 'pages/Confirm';
import ChangePasswordUnauthed from 'pages/ChangePassword';
import { useSelector } from "react-redux";
import LoadingMask from "components/LoadingMask";
import Footer from "./components/Footer/Footer.js";

const hist = createBrowserHistory();


export default function App({ ...props }) {
  const page = useSelector(state => state.navigation.page);
  const loading = useSelector(state => state.mask.loading);

  //console.log(loggedIn());

  return (
  <Router history={hist}>
        {loading ? <LoadingMask /> : <div />}
    <Switch>
      <Route path="/user" render= {() => {if(loggedIn())  {return <User />} else {return <Redirect to="/login" />} }} />
      <Route path="/login" render= {() => { if( loggedIn() && page === 'showLoginConfirmed' ){ return <Redirect to="/user" /> } else { return <Login /> }}} />
      {/*<Route path="/cometlogin" render= {() => { if( loggedIn() && page ==='showLoginConfirmed' ){ return <Redirect to="/user" /> } else { return <CometLogin /> }}} />*/}
              <Route path="/register" render={() => { if (loggedIn() && page === 'showRegisterConfirmed') { return <GenericMessagePage message="Your registration email has been sent, you will need to follow the instructions in the email to confirm your account before you will be allowed to log in." />  } else { return <Register /> } }} />
              <Route path="/forgot" render={() => {
                  if (page === "showResetConfirmed") {

                      return <GenericMessagePage message="Password reset email has been sent. Please check your spam filter if you have not received it." />
                  } else { return <Forgot /> }
              }} />
              <Route path="/resend" render={() => {
                  if (page === "showResendConfirmed") {

                      return <GenericMessagePage message="Your registration email has been resent, you will need to follow the instructions in the email to confirm your account before you will be allowed to log in. Please check your spam filter if you have not received it." />
                  } else { return <Resend /> }
              }} />



              <Route path="/reset/:encoded" render={() => { if (page === "showChangePasswordConfirmed") { if (loggedIn()) { return <Redirect to="/user" /> } else { return <Redirect to="/login" /> } } else { return <ChangePasswordUnauthed /> } }} />

              <Route path="/confirm/:encoded" render={() => {
                  if (page === "confirmedConfirmed") {

                      return <GenericMessagePage message="Your account has been confirmed. You may now log in." />
                  } else { return <Confirm /> }
              }} />


              <Redirect from="/" to={loggedIn() ? "/user" : "/login"} />
    </Switch>
    <Footer />
  </Router>
  )
}


