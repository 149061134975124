import React from 'react';
import { Field, reduxForm } from 'redux-form';
import GeneralTextField from "../TextFields/GeneralTextField";
import { connect } from 'react-redux'
import Button from "../CustomButtons/Button";
import Copyright from "../Copyright";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { submitAsyncValidation } from "../../utils/forms";
import { useHistory } from "react-router-dom";
import {
    validate,
    warn
} from "../../validators/forms";
import { FRONTEND } from 'constants/api';

const ConfirmAccountForm = props => {
    const { handleSubmit, submitting } = props
    let history = useHistory();

    return (
        <form onSubmit={submitAsyncValidation(
            handleSubmit,
            true,
            props.onSubmit
        )}>

            <div style={{ display: 'none' }}>
                <Field

                    name="email"
                    id="email"
                    component={GeneralTextField}
                    label="Email Address"
                    type="text"
                    autoComplete="email"
                />

                <Field

                    name="code"
                    id="code"
                    component={GeneralTextField}
                    label="Confirmation Code"
                    type="text"
                    autoComplete="confirmation-code"
                />
            </div>

            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className="submit"
                disabled={submitting}
            >
                Push to Confirm Account
            </Button>

            <Grid container>

                <Grid item>
                    <Link onClick={() => history.push('/login')} variant="body2">
                        {"Login"}
                    </Link>
                </Grid>
            </Grid>
            {FRONTEND !== "on" && <Box mt={5}>
                <Copyright />
            </Box>}
        </form>
    )
}

let changeauth = reduxForm({
    form: 'confirmAccountForm',
    validate,
    warn,
})(ConfirmAccountForm)

const mapStateToProps = (state, props) => ({
    initialValues: props.defaultValue,
})

export default connect(mapStateToProps)(changeauth)