import React from 'react';
import { Field, reduxForm } from 'redux-form';
import GeneralTextField from "../TextFields/GeneralTextField";
import Button from "../CustomButtons/Button";
import Copyright from "../Copyright";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { submitAsyncValidation } from "../../utils/forms";
import { useHistory } from "react-router-dom";
import {
    validate,
    warn
} from "../../validators/forms";

const ResendRegistrationEmailForm = props => {
    const { handleSubmit, submitting } = props
    let history = useHistory();
    return (
        <form onSubmit={submitAsyncValidation(
            handleSubmit,
            true,
            props.onSubmit
        )}>

            <Field
                name="email"
                id="email"
                component={GeneralTextField}
                label="Email"
                type="text"
                autoComplete="email"
            />

            <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className="submit"
                disabled={submitting}
            >
                ReSend Registration Email
            </Button>

            <Grid container>
                <Grid item>
                    <Link onClick={() => history.push('/login')} variant="body2">
                        {"Login"}
                    </Link>
                </Grid>
            </Grid>


            <Box mt={5}>
                <Copyright />
            </Box>
        </form>
    )
}

export default reduxForm({
    form: 'resendRegistrationEmailForm',
    validate,
    warn
})(ResendRegistrationEmailForm)