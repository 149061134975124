import React, {useEffect, useState, useCallback } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "../../components/Grid/GridItem.js";
import TableGrid from "../../components/Table/TableGrid.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import DynamicDialog from "../../components/Dialogs/Dialog.js";
import { USER_FETCH_ALL, USER_ADMIN_UPDATE } from "../../constants/api";
import { useSelector, useDispatch } from 'react-redux'
import { updateLoading } from "../../actions/mask";
import { headerWithAuth } from "../../utils/auth";
import * as helpers from "../../helpers/helpers";
import { userColumns, userSort } from "helpers/gridHelper.js";
import EditUserForm from "../../components/Forms/EditUserForm";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  },
  cardTitleBlack: {
    color: "#000",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

export default function Management() {
  const [users, setUsers] = React.useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [dialogMessage, setDialogMessage] = React.useState('Would you like to download the selected records?');
  const [showDialog, setDialog] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState('Download ledger');
  const [dialogKey, setDialogKey] = React.useState('n');
  const [selectedRow, setSelectedRow] = React.useState(null); //Holder for selected row so we can update management info..
  const [ggitAccessCheck, setGgitCheckAccess] = React.useState(false);
  const [crAcessCheck, setCrCheckAccess] = React.useState(false);
  const [ggit30AccessCheck, setGgit30CheckAccess] = React.useState(false);
  const classes = useStyles();
  const currentUser = useSelector(state => state.user);

  const dispatch = useDispatch();

  const fetchUsers = useCallback((showMask) => {

    if (firstLoad || showMask)//We only want to show mask for first load since this is a periodic fetch
      dispatch((updateLoading({ loading: true })));

    var data = {
      id: currentUser.id
    }

    fetch(USER_FETCH_ALL, {
      method: "POST", body: JSON.stringify(data),
      headers: headerWithAuth()
    }).then(response => {
      if (response.status === 200) //Ok may have json data may not.
      {
        return response.json() // return the result of the inner promise, which is an error
          .then((json) => {
           // console.log(json);
           setUsers(json);

            setFirstLoad(false);//Set first load to false after we know this has been called once.
            dispatch((updateLoading({ loading: false })));

            return null;
          });
      }

      else //Some error before uploading the files
      {
        setFirstLoad(false);//Set first load to false after we know this has been called once.
        dispatch((updateLoading({ loading: false })));
      }
    })
      .catch((error) => {
        console.error('Error:', error);
        setFirstLoad(false);//Set first load to false after we know this has been called once.
        dispatch((updateLoading(false)));
      });
  }, [currentUser.id, firstLoad, dispatch]);

  const saveData = () =>
  {
    resetDialogs();
    dispatch((updateLoading({ loading: true })));

    var data = {
      adminUserId: currentUser.id,
      editUserId: selectedRow.id,
      crAccess: crAcessCheck,
      ggitAccess: ggitAccessCheck,
      ggit30Access: ggit30AccessCheck
    }

    fetch(USER_ADMIN_UPDATE, {
      method: "POST", body: JSON.stringify(data),
      headers: headerWithAuth()
    }).then(response => {
      if (response.status === 200) //Ok may have json data may not.
      {
        updateDialog('User Record Updated ', "Changes changed successfully.", true);
        fetchUsers(true); //Get updated records, show mask.
      }

      else //Some error before uploading the files
      {
        console.error('User data did not save');
        updateDialog('User data did not save', "An error occured while attempting to save the data.", true);
        dispatch((updateLoading({ loading: false })));
      }
    })
      .catch((error) => {
        console.error('Error:', error);
        dispatch((updateLoading(false)));
      });
  }

  const updateDialog = (title, message, show) => {
    setDialogTitle(title)
    setDialogMessage(message);
    setDialog(show);
  }

  const editUser = (event) => {

    if (event.row) {
     var email = event.row.email;
      var message = 'Use the controls below to edit the user account associated with email id: ' + email + '.';
      setDialogKey('e');
      updateDialog('Edit User ', message, true);
      setSelectedRow(event.row);
    }
  }

  const resetDialogs = () => {
    setDialog(false);
    setDialogKey('n');
  }

  const updateCr = (value) => {
    setCrCheckAccess(value);
    }


    const updateGgit = (value) => {
      setGgitCheckAccess(value);
    }

    const updateGgit30 = (value) => {
      setGgit30CheckAccess(value);    
    }

    const getAdminBool = (role) =>{

      var adminBool = false;

      if(role === 'a')
      adminBool = true;

      return adminBool;
    }

  const getEditUserForm = () =>{

    if(selectedRow)
    {
      let adminBool = getAdminBool( selectedRow.role);
      return <EditUserForm userUpdateCr={updateCr} userUpdateGgit={updateGgit} userUpdateGgit30={updateGgit30} userGgitChecked={selectedRow.ggitAccess} userGgit30Checked={selectedRow.ggit30Access} userCrChecked={selectedRow.crAccess} userAdmin={adminBool}  />
    }
    //This should never happen
    return <EditUserForm userUpdateCr={updateCr} userUpdateGgit={updateGgit} userUpdateGgit30={updateGgit30} userGgitChecked={null} userGgit30Checked={null} userCrChecked={null} userAdmin={null}  />
  }

  var cancelButton = helpers.getDialogButton("Cancel", resetDialogs);
  var okButton = helpers.getDialogButton("Ok", resetDialogs);
  var saveButton = helpers.getDialogButton("Save", saveData);

  var normalDialogButtons = [okButton];
  var editDialogButtons = [cancelButton, saveButton];

  var dialogButtons = {
    n: normalDialogButtons,
    e: editDialogButtons
  }

  const editForm = getEditUserForm();

  /*Set the selected users as this user. By default.*/
  useEffect(() => {

    if (firstLoad)
    {
      fetchUsers(true);
      setFirstLoad(false);
    }

  }, [firstLoad, fetchUsers, setFirstLoad]);

  return (
    <div>
      <GridContainer>
      <GridItem xs={12} sm={12} md={12}>
      <h4 className={classes.cardTitleBlack}> Click the record you would like to edit</h4>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" >
              <GridContainer direction='row' justify="space-between" alignItems="flex-start">
                <GridItem xs={6} sm={6} md={6}>
                  <h4 className={classes.cardTitleWhite}> Manage Users</h4>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <TableGrid
                tableHeaderColor="primary"
                rows={users}
                size={100}
                columns={userColumns}
                sortModel={userSort}
                onRowClick={event => editUser(event) }
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <DynamicDialog
        buttons={dialogButtons[dialogKey]}
        open={showDialog}
        labelId={"editUserDialog"}
        title={dialogTitle}
        textMessage={dialogMessage}
        form={editForm}
      />
    </div>
  );
}
