import { select } from "redux-saga/effects";
//import { useHistory } from "react-router-dom";

const enters = {};

export function* enterPage() {
  const state = yield select();
  const pageName = state.navigation.page;
  const entersFunc = enters[pageName];
  if (entersFunc) yield entersFunc(state);
}

export function startApp() {

//onsole.log('To to blank');
  window.history.pushState({}, "", "user");
}

const exits = {};

export function* exitPage({ payload }) {
  const state = yield select();

  const exitsFunc = exits[payload];
  if (exitsFunc) yield exitsFunc(state);
}
