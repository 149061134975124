import React from "react";
import Checkbox from "@material-ui/core/Checkbox";


export default function GeneralCheck({meta: { touched, error, warning }, ...props }) {
  
    const handleChange = (e) => {
        console.log(e);
        console.log('general textfield not working correctly');
    }    
//console.log(props);
  return (
    <div>
  <Checkbox
    color="primary"
    onChange={props.input ?  props.input.onChange : handleChange} 
    type={props.type}
    autoFocus
  />
  <div> 
    {touched &&
        ((error && <span>{error}</span>) ||
          (warning && <span>{warning}</span>))} </div>
  </div>
  );
}
