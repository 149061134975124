import {
  drawerWidth,
  transition,
  container
} from "assets/jss/material-dashboard-react.js";
import { FRONTEND } from "constants/api";

const cometHeader= require("assets/img/Comet_Farm_Header.png");
const backgroundImage = require("assets/img/home-page.png");
const whiteListBackgroundImage = require("assets/img/home-page-2.png");

const backgroundToUse = (FRONTEND !== "on" ? backgroundImage : whiteListBackgroundImage);

const appStyle = theme => ({
  wrapper: {
    position: "relative",
    top: "0",
    height: "100vh"
  },
  mainPanel: {
    [theme.breakpoints.up("md")]: {
      width: `calc(100% - ${drawerWidth}px)`
    },
    overflow: "auto",
    position: "relative",
    float: "right",
    ...transition,
    maxHeight: "100%",
    width: "100%",
    overflowScrolling: "touch"
  },
  content: {
    marginTop: "70px",
    padding: "30px 15px",
    minHeight: "calc(100vh - 123px)"
  },
  container,
  map: {
    marginTop: "70px"
  },
  root: {
    height: '100vh',
    },
    cometHeader: {
        backgroundImage: `url(${cometHeader})`,
        backgroundRepeat: 'no-repeat',
        backgroundColor:
            theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
      
        backgroundPosition: 'left',
        width: '100%',
        height: '100px',
    },
  image: {
    backgroundImage: `url(${backgroundToUse})`,
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

export default appStyle;
