import React from "react";
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from "@material-ui/core/styles";
import ConfirmAccountForm from "../components/Forms/ConfirmAccountForm";
import { useDispatch } from "react-redux";
import { submitConfirmAccount } from "../actions/auth";
import { useParams } from "react-router-dom";
// core components
import styles from "assets/jss/material-dashboard-react/layouts/loginStyle.js";


const useStyles = makeStyles(styles);


export default function Confirm({ ...rest }) {
    const dispatch = useDispatch();
    // styles
    const classes = useStyles();

    let defaultValue = { email: '', code: '' };
    let { encoded } = useParams();
    try {
        let decoded = atob(encoded);
        console.log(decoded);
        var collectedObject = JSON.parse(decoded);
        console.log(collectedObject)

        defaultValue.email = collectedObject['email'];
        defaultValue.code = collectedObject['code'];


    }
    catch (e) {
        console.log(e)
    }


    const submit = props => {
        console.log('submitting', props);
        dispatch((submitConfirmAccount(props)));
    }

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} className={classes.image} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <HowToRegIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Confirm Account
          </Typography>
                    <ConfirmAccountForm onSubmit={submit} defaultValue={defaultValue} />
                </div>
            </Grid>
        </Grid>
    );
}
