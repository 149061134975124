import _ from "lodash";

export const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const WEBSITE_REGEX = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/;

export const ZIP_NUMBERS_SPACES_HYPHON_REGEX = /^(?=.*[0-9])[- 0-9]+$/;

export const NAME_REGEX = /[a-z\s\b]+$/i;

export const ADDRESS_REGEX = /[^a-z.#A-Z0-9\s-]/;

const minLength = min => value =>
  value && value.length < min ? `Length should be more than ${min}` : undefined;

const minLengthZip = min => value =>
  value && value.length < min
    ? `Length should be ${min} or zip + 4`
    : undefined;

const minLengthP = min => value =>
  value && value.length < min ? `Invalid phone number` : undefined;

export const required = value => (value ? undefined : "Required");

export const moreThan = (
  limit,
  message = `Should be more than ${limit}`
) => n => (n > limit ? undefined : message);

export const lessThan = (
  limit,
  message = `Should be less than ${limit}`
) => n => (n < limit ? undefined : message);

export const integer = n =>
  n.toString().includes(".") ? "Should be integer" : undefined;

export const lengthMoreThan = (
  limit,
  message = `Length should be more than ${limit}`
) => str => (!str || str.length < limit ? message : undefined);

export const lengthLessThan = (
  limit,
  message = `Length should be less than ${limit}`
) => str => (!str || str.length > limit ? message : undefined);


export const zipCode = value =>
  value &&
  (!ZIP_NUMBERS_SPACES_HYPHON_REGEX.test(value) ||
    value.length < 5 ||
    value.length > 10)
    ? "Invalid zipcode"
    : undefined;

export const minLength6 = minLength(6);

export const minLength4 = minLength(4);

export const minLength5 = minLength(5);

export const minLength14 = minLengthP(14);
export const minLengthForZip = minLengthZip(5);

export const lengthLessThan40 = lengthLessThan(40);

export const lengthLessThan100 = lengthLessThan(100);

export const lengthLessThan500 = lengthLessThan(500);

export const email = value =>
  !value
    ? undefined
    : !EMAIL_REGEX.test(value.replace(/ /g, ""))
    ? "Invalid email address"
    : undefined;

export const emailOptional = value => {
  if (value === undefined) return undefined;

  return !EMAIL_REGEX.test(value) ? "Invalid email address" : undefined;
};

export const beginsWithNonAlpha = value => {
  value.length === 1 && value.match(/[a-zA-Z]/i);
};

export const uniqueAmong = (values, message = "Should be unique") => v =>
  v && _.includes(values, v) ? message : undefined;

export const website = value =>
  value && !WEBSITE_REGEX.test(value) ? "Invalid website" : undefined;

export const name = value =>
  value && NAME_REGEX.test(value)
    ? undefined
    : "Name should not contain any numbers or special characters.";

export const address = value =>
  value && !ADDRESS_REGEX.test(value)
    ? undefined
    : "No special characters allowed";

export const beginsWithAlpha = value =>
  value.substring(0, 1).match(/[a-z]/i) ? undefined : "Invalid entry";

export const beginsWithNumeric = value =>
  value.substring(0, 1).match(/[0-9]/) ? undefined : "Invalid entry";

export const validate = values => {
    const errors = {}
    if (!values.username) {
      errors.username = 'Required'
    } else if (values.username.length > 15) {
      errors.username = 'Must be 15 characters or less'
    }
    if (!values.email) {
      errors.email = 'Required'
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address'
    }
    if (!values.age) {
      errors.age = 'Required'
    } else if (isNaN(Number(values.age))) {
      errors.age = 'Must be a number'
    } else if (Number(values.age) < 18) {
      errors.age = 'Sorry, you must be at least 18 years old'
    }

    if (!values.terms) {
      errors.terms = 'Please accept the terms and conditions.'
    }

    if (!values.password) {
      errors.password = 'Required';
    }
    if (!values.confirmpassword ) {
      errors.confirmpassword = 'Required' ;
    } else if (values.confirmpassword !== values.password) {
      errors.confirmpassword = 'Password mismatched' ;
    }

    if (!values.first) {
      errors.first = 'Required';
    }

    if (!values.last) {
      errors.last = 'Required';
    }

  //  console.log(errors);
    return errors
  }
  
 export const warn = values => {
    const warnings = {}
    if (values.age < 19) {
      warnings.age = 'Hmm, you seem a bit young...'
    }
    return warnings
  }