import { createReducer } from 'redux-act'
import * as a from '../actions/mask'

const getDefaultState = _ => ({
  loading: false
})

export default _ =>
  createReducer(
    {
      [a.updateLoading]: (state, { loading }) => ({
        ...state,
        loading
      }),
    },
    getDefaultState()
)