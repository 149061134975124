import React from 'react';
import { Field, reduxForm } from 'redux-form';
import GeneralTextField from "../TextFields/GeneralTextField";
import Button from "../CustomButtons/Button";
import Copyright from "../Copyright";
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { submitAsyncValidation } from "../../utils/forms";
import { useHistory } from "react-router-dom";
import {
    validate,
    warn
  } from "../../validators/forms";
import { FRONTEND } from 'constants/api';

const LoginForm = props => {
  const { handleSubmit, submitting, showPass } = props
  let history = useHistory();
  return (
    <form onSubmit={submitAsyncValidation(
      handleSubmit,
      true,
      props.onSubmit
    )}>

            <Field
              name="email"
              id="email"
              component={GeneralTextField}
              label="Email"
              type="text"
              autoComplete="email"
            />

            <Field
                name="password"
                id="password"
                component={GeneralTextField}
                label="Password"
                autoComplete="current-password"
                type={showPass ? "text" : "password"}
              />    

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="submit"
              disabled={submitting}
            >
              Sign In
            </Button>

            <Grid container>
              <Grid item xs={4}>
                  <Link onClick={() => history.push('/forgot')} variant="caption">
                      Forgot password?
                </Link>
                  </Grid>
              <Grid item xs={4}>
                  <Link onClick={() => history.push('/resend')} variant="caption">
                       Resend Confirmation
                </Link>
              </Grid>
              <Grid item xs={4}>
                  <Link onClick={() => history.push('/register')} variant="caption">
                  {"Register"}
                </Link>
              </Grid>
                 
         
          </Grid>
          <Grid item>
              {/*<Link onClick={() => history.push('/cometlogin')} variant="body2">
                  {"Use COMET-Farm credentials"}
    </Link>*/}
          </Grid>
            {FRONTEND !== "on" && <Box mt={5}>
              <Copyright />
            </Box>}
    </form>
  )
}

export default reduxForm({
  form: 'loginForm',
  validate,
  warn
})(LoginForm)