import React, { useEffect, useState, useCallback } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
//import { useApiRef } from '@material-ui/x-grid';
// core components
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import TableGrid from "../../components/Table/TableGrid.js";
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";

import { useSelector, useDispatch } from 'react-redux'
import { updateLoading } from "../../actions/mask";
import { USER_FETCH_RUNS, USER_FETCH_QUEUE, USER_DOWNLOAD, USER_CANCEL_RUN, USER_DOWNLOAD_ALL } from "../../constants/api";
import { saveAs } from 'file-saver';
import DynamicDialog from "../../components/Dialogs/Dialog.js";
import * as helpers from "../../helpers/helpers";
import { headerWithAuth } from "../../utils/auth";
import {historyColumns, runColumns, qColumns, defaultSortQ, defaultSortRun, defaultSortHistory } from "../../helpers/gridHelper";
import { useApiRef } from "@material-ui/x-grid";

const styles = {
  cardCategoryWhite: {
    "&,& a,& a:hover,& a:focus": {
      color: "rgba(255,255,255,.62)",
      margin: "0",
      fontSize: "14px",
      marginTop: "0",
      marginBottom: "0"
    },
    "& a,& a:hover,& a:focus": {
      color: "#FFFFFF"
    }
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none",
    "& small": {
      color: "#777",
      fontSize: "65%",
      fontWeight: "400",
      lineHeight: "1"
    }
  }
};

const useStyles = makeStyles(styles);

export default function Runs() {
  const classes = useStyles();
  const [runs, setRuns] = React.useState([]);
  const [running, setRunning] = React.useState([]);
  const [queue, setQ] = React.useState([]);
  const [firstLoad, setFirstLoad] = useState(true);
  const [dialogMessage, setDialogMessage] = React.useState('Would you like to download your results the selected records?');
  const [showDialog, setDialog] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState('Download run');
  const [dialogKey, setDialogKey] = React.useState('n'); //This is to help switch on which buttons to display in dialog.  Values can be n - normal message, h - history, q - queue, r - running
  const [selectedRow, setSelectedRow] = React.useState(null); //Holder for selected row so we can confirm downloads and deletes etc...
  const currentUser = useSelector(state => state.user);
  const [count, setCount] = useState(0);
  const dispatch = useDispatch();
    const apiRefHistory = useApiRef();


  const tick = () => {
    setCount((prevState) => prevState < 15 ? prevState + 1 : 0);
  }

  const fetchRunHistory = useCallback((showMask) => {

    if (firstLoad || showMask)//We only want to show mask for first load since this is a periodic fetch
      dispatch((updateLoading({ loading: true })));

    var data = {
      Id: currentUser.id
    }

    fetch(USER_FETCH_RUNS, {
      method: "POST", body: JSON.stringify(data),
      headers: headerWithAuth()
    }).then(response => {
      if (response.status === 200) //Ok may have json data may not.
      {
        return response.json() // return the result of the inner promise, which is an error
          .then((json) => {
            // console.log(json);
            if (firstLoad)
              setRuns(json);

              apiRefHistory.current.updateRows(json);


            let runningRuns = json.filter(r => (r.status === 's' || r.status === 'sw'));
            setRunning(runningRuns);

            setFirstLoad(false);//Set first load to false after we know this has been called once.
            dispatch((updateLoading({ loading: false })));

            return null;
          });
      }

      else //Some error before uploading the files
      {
        setFirstLoad(false);//Set first load to false after we know this has been called once.
        dispatch((updateLoading({ loading: false })));
      }
    })
      .catch((error) => {
        console.error('Error:', error);
        setFirstLoad(false);//Set first load to false after we know this has been called once.
        dispatch((updateLoading(false)));
      });

  }, [apiRefHistory, currentUser.id, dispatch, firstLoad]);

  const fetchQueue = useCallback(() => {

    var data = {
      Id: currentUser.id
    }

    fetch(USER_FETCH_QUEUE, {
      method: "POST", body: JSON.stringify(data),
      headers: headerWithAuth()
    }).then(response => {
      if (response.status === 200) //Ok may have json data may not.
      {
        return response.json() // return the result of the inner promise, which is an error
          .then((json) => {
            // console.log(json);
            setQ(json);

            //Keep calling until queue is empty
            return null;
          });
      }

      else
      {
        //TODO
      }
    })
      .catch((error) => {
        console.error('Error:', error);
      });

    }, [currentUser.id]);

    const downloadAll = () => {
        resetDialogs();


        var fileName = 'results_' + selectedRow.originalFileName;

        var data = {
            ModelRunId: selectedRow.id,

        }

        if (selectedRow.id) {
            fetch(USER_DOWNLOAD_ALL, {
                method: "POST", body: JSON.stringify(data),
                headers: headerWithAuth()
            }).then(response => {
                if (response.status === 200) //Ok may have json data may not.
                {

                    return response.blob() // return the result of the inner promise, which is an error
                        .then((res) => {

                            const blob = new Blob([res], {
                                type: 'application/zip'
                            });

                            saveAs(blob, fileName+"_full.zip")
                        })
                }

                else { //Response was not 200
                    updateDialog('Download Failed', 'Response status : ' + response.status, true);
                }

            })
                .catch((error) => {
                    console.error('Error:', error);
                    updateDialog('Download Failed', error, true);
                });
        }

        else {
            updateDialog('Download Failed', 'Unable to locate the run: ' + selectedRow.originalFileName, true);
        }
    }

  const downloadHistory = () => {
    resetDialogs();


    var fileName = 'results_' + selectedRow.originalFileName;

      var data = {
          ModelRunId: selectedRow.id,

      }

      if (selectedRow.id) {
      fetch(USER_DOWNLOAD, {
        method: "POST", body: JSON.stringify(data),
        headers: headerWithAuth()
      }).then(response => {
        if (response.status === 200) //Ok may have json data may not.
        {

          return response.blob() // return the result of the inner promise, which is an error
            .then((res) => {
              // console.log(res);
              const blob = new Blob([res], {
                type: 'application/octet-stream'
              });

              saveAs(blob, fileName)
            })
        }

        else { //Response was not 200
          updateDialog('Download Failed', 'Response status : ' + response.status, true);
        }

      })
        .catch((error) => {
          console.error('Error:', error);
          updateDialog('Download Failed', error, true);
        });
    }

    else {
      updateDialog('Download Failed', 'Unable to locate the run: ' + selectedRow.originalFileName, true);
    }

  }

  const cancelQueue = () => {
    resetDialogs();
    //console.log(selectedRow);
    var id = selectedRow.id;
    //console.log(selectedRow);

    var data = {
      Id: currentUser.id,
      UserFileId: id
    }

    fetch(USER_CANCEL_RUN, {
      method: "POST", body: JSON.stringify(data),
      headers: headerWithAuth()
    }).then(response => {
      if (response.status === 200) //Ok may have json data may not.
      {
        return response.json() // return the result of the inner promise, which is an error
          .then((json) => {
            updateDialog('Cancel Success', 'Run ' + selectedRow.fileName + ' cancelled successfully.', true);
            setQ(json);
          });
      }

      else { //Response was not 200
        updateDialog('Cancel Failed', 'Response status : ' + response.status, true);
      }
    })
      .catch((error) => {
        console.error('Error:', error);
        updateDialog('Cancel Failed', error, true);
      });
  }

  const confirmHistoryDownload = (event) => {
    let fileName = "";

    if (event.row) {
      fileName = event.row.originalFileName;
      // console.log(event.row);
      var message = ''

      if (event.row.status === 'fe' || event.row.status === 'f') {
        message = 'No download available for ' + fileName + '. An error has been thrown for this run.  Check your error logs for more information.';
        setDialogKey('n');
        updateDialog('No Download', message, true);
        setSelectedRow(event.row);
      }

      else {
        message = 'Would you like download ' + fileName + '?';
        setDialogKey('h');
        updateDialog('Confirm Download', message, true);
        setSelectedRow(event.row);
      }
    }

  }

  const confirmQueueCancel = (event) => {
    if (event.row) {
      var fileName = event.row.fileName;
      var message = 'Would you like cancel ' + fileName + '?';
      setDialogKey('q');
      updateDialog('Confirm Run Cancel ', message, true);
      setSelectedRow(event.row);
    }
  }


  const updateDialog = (title, message, show) => {
    setDialogTitle(title)
    setDialogMessage(message);
    setDialog(show);
  }

  const resetDialogs = () => {
    setDialog(false);
    setDialogKey('n');
  }

  var cancelButton = helpers.getDialogButton("Cancel", resetDialogs);
  var okButton = helpers.getDialogButton("Ok", resetDialogs);
    var confirmRunDownloadButton = helpers.getDialogButton("Download", downloadHistory);
    var confirmFullResultDownloadButton = helpers.getDialogButton("Download All Associated Results", downloadAll);
  var confirmQueueCancelButton = helpers.getDialogButton("Confirm", cancelQueue);

  var normalDialogButtons = [okButton];
    var historyDialogButtons = [cancelButton, confirmRunDownloadButton, confirmFullResultDownloadButton];
  var queueDialogButtons = [cancelButton, confirmQueueCancelButton];
    var runDialogButtons = [cancelButton, confirmRunDownloadButton, confirmFullResultDownloadButton];

  //Just switching on buttons here.  Different buttons need to trigger specific functions.
  var dialogButtons = {
    n: normalDialogButtons,
    h: historyDialogButtons,
    q: queueDialogButtons,
    r: runDialogButtons
  }

  useEffect(() => {
    const timer = setInterval(() => tick(), 1000);
    return () => clearInterval(timer); //When we leave the page the timer will clear.
  });

  //Call fetch logs every 10 seconds
  useEffect(() => {
    if (count === 0)
      fetchRunHistory();

    if (count === 0)
      fetchQueue();

  }, [count, fetchRunHistory, fetchQueue]);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="info" >
              <GridContainer direction='row' justify="space-between" alignItems="flex-start">
                <GridItem xs={6} sm={6} md={6}>
                  <h4 className={classes.cardTitleWhite}>Running</h4>
                  <p className={classes.cardCategoryWhite}>
                    Currently running
            </p>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <p className={classes.cardTitleWhite}> {"Table auto-rereshing in " + ((count - 15) * -1) + " sec(s)"} </p>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <TableGrid
                tableHeaderColor="primary"
                rows={running}
                columns={runColumns}
                sortModel={defaultSortRun}
                height={400}
              />
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="warning" >
              <GridContainer direction='row' justify="space-between" alignItems="flex-start">
                <GridItem xs={6} sm={6} md={6}>
                  <h4 className={classes.cardTitleWhite}>Active Queue</h4>
                  <p className={classes.cardCategoryWhite}>
                    Runs currently in the queue
            </p>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <p className={classes.cardTitleWhite}> {"Table auto-rereshing in " + ((count - 15) * -1) + " sec(s)"} </p>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <TableGrid
                tableHeaderColor="primary"
                rows={queue}
                columns={qColumns}
                checkboxSelection
                height={400}
                sortModel={defaultSortQ}
                onRowClick={event => confirmQueueCancel(event)}
              />
            </CardBody>
          </Card>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <Card>
            <CardHeader color="primary">
              <GridContainer direction='row' justify="space-between" alignItems="flex-start">
                <GridItem xs={6} sm={6} md={6}>
                  <h4 className={classes.cardTitleWhite}>Run History</h4>
                  <p className={classes.cardCategoryWhite}>
                    All the model runs you've ever run
            </p>
                </GridItem>
                <GridItem xs={6} sm={6} md={6}>
                  <p className={classes.cardTitleWhite}> {"Table auto-rereshing in " + ((count - 15) * -1) + " sec(s)"} </p>
                </GridItem>
              </GridContainer>
            </CardHeader>
            <CardBody>
              <TableGrid
                tableHeaderColor="primary"
                rows={runs}
                columns={historyColumns}
                height={800}
                autoPageSize
                apiRef={apiRefHistory}
                sortModel={defaultSortHistory}
                onRowClick={event => confirmHistoryDownload(event)}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <DynamicDialog
        buttons={dialogButtons[dialogKey]}
        open={showDialog}
        labelId={"historyDialog"}
        title={dialogTitle}
        textMessage={dialogMessage}
      />
    </div>
  );
}
