import React from "react";
// nodejs library that concatenates classes
// nodejs library to set properties for components
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Typography from '@material-ui/core/Typography';

// core components
import styles from "assets/jss/material-dashboard-react/components/dialogStyle.js";

const useStyles = makeStyles(styles);

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

export default function DynamicDialog(props) {

  const classes = useStyles();
  /*const { className, ...rest } = props;
  const dialogClasses = classNames({
    [classes.normal]: true,
    [className]: className !== undefined
  });*/

  let { fieldName } = props;

  return (
      <Dialog
        open={props.open}
        TransitionComponent={Transition}
        keepMounted={props.keepMounted ? true : false}
        aria-labelledby={props.labelId}
        aria-describedby={props.labelId + "desc"}
        classes={{ paper: classes.dialogPaper }} >
            {props.loadingMask &&  props.loadingMask }
        <DialogTitle
          disableTypography={true}
          classes={{
            root: props.form
              ? classes.dialogTitleRootForm
              : classes.dialogTitleRoot, }}>
          <div style={{ display: "flex" }}>
            {fieldName ? fieldName + "\t" : ""}
            {props.title}
          </div>
        </DialogTitle>
        <DialogContent>
          <div>
          <Typography component="h3" variant="h5">
          {props.textMessage && <div>{props.textMessage}</div>}
              </Typography>
            {props.form && <div>{props.form}</div>}
            {props.children && <div>{props.children}</div>}
          </div>
        </DialogContent>
        <DialogActions>
          {props.buttons && (
            <div>
              {props.buttons.map((button, index) => (
                <div key={"button" + index} style={{ float: "left", marginRight: "10px" }} >
                  {button}
                </div>
              ))}
            </div>
          )}
        </DialogActions>
      </Dialog>
    );

}

DynamicDialog.propTypes = {
  className: PropTypes.string,
};
