import React, { useEffect, useState, useCallback } from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Card from "../../components/Card/Card.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardBody from "../../components/Card/CardBody.js";
import { useSelector, useDispatch } from 'react-redux';
import { updateLoading } from "../../actions/mask";
import { USER_FETCH_LOGS_BY_USERFILE, CLEAR_LOGS } from "../../constants/api";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Alert from '@material-ui/lab/Alert';
import Divider from '@material-ui/core/Divider';
import { headerWithAuth } from "../../utils/auth";
import Button from "../../components/CustomButtons/Button.js";
import GridItem from "../../components/Grid/GridItem.js";
import GridContainer from "../../components/Grid/GridContainer.js";
import { getDateString } from "../../utils/generic";

const styles = {
  typo: {
    paddingLeft: "25%",
    marginBottom: "40px",
    position: "relative"
  },
  note: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    bottom: "10px",
    color: "#c0c1c2",
    display: "block",
    fontWeight: "400",
    fontSize: "13px",
    lineHeight: "13px",
    left: "0",
    marginLeft: "20px",
    position: "absolute",
    width: "260px"
  },
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  },
  alertRoot: {
    margin: "3px",
    flexBasis: '100%'
  },
  column: {
    flexBasis: '50%',
  }
};

const useStyles = makeStyles(styles);

export default function LogsPage() {
  const classes = useStyles();

  const [userFiles, setUserFiles] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [logCount, setLogCount] = useState(0);
  const currentUser = useSelector(state => state.user);
  const [count, setCount] = useState(0);
  const [searchText, setSearchText] = useState("");
  const [firstLoad, setFirstLoad] = useState(true);
  const [hours, setHours] = useState(1);
  const [info, setInfo] = useState(true);//need user to be able to select any type
  const [error, setError] = useState(true);//need user to be able to select any type
  const [debug, setDebug] = useState(true);//need user to be able to select any type
  const dispatch = useDispatch();

  const tick = () => {
    setCount((prevState) => prevState < 60 ? prevState + 1 : 0);
  }

  const clearLogs = (userFileId) => {
    dispatch((updateLoading({ loading: true })));

    var data = {
      Id: currentUser.id,
      userFileId: userFileId
    }

    fetch(CLEAR_LOGS, {
      method: "POST", body: JSON.stringify(data),
      headers: headerWithAuth()
    }).then(response => {
      if (response.status === 200) //Ok may have json data may not.
      {
        fetchUserFiles();
        return null;
      }

      else //Some error before uploading the files
      {//
        dispatch((updateLoading({ loading: false })));
        return null;
      }
    })
      .catch((error) => {
        console.error('Error:', error);
        dispatch((updateLoading({ loading: false })));
      });
  }

  const fetchUserFiles = useCallback((showMask) => {
    if (firstLoad || showMask)//We only want to show mask for first load since this is a periodic fetch
      dispatch((updateLoading({ loading: true })));

    var data = {
      Id: currentUser.id,
      Hours: hours
    }

    fetch(USER_FETCH_LOGS_BY_USERFILE, {
      method: "POST", body: JSON.stringify(data),
      headers: headerWithAuth()
    }).then(response => {
      if (response.status === 200) //Ok may have json data may not.
      {
        return response.json() // return the result of the inner promise, which is an error
          .then((json) => {
            //console.log(json);
            setUserFiles(json);
            setFirstLoad(false);//Set first load to false after we know this has been called once.
            dispatch((updateLoading({ loading: false })));
            return null;
          });
      }

      else //Some error before uploading the files
      {// return response.json();

        setFirstLoad(false);//Set first load to false after we know this has been called once.
        dispatch((updateLoading({ loading: false })));
        return null;
      }
    })
      .catch((error) => {
        console.error('Error:', error);
        setFirstLoad(false);
        dispatch((updateLoading({ loading: false })));
      });
  }, [currentUser.id, dispatch, firstLoad, hours]);

  const handleSearchChanged = (event) => {
    setSearchText(event.target.value);
  }

  const filterUserFilesAndLogs = useCallback(() => {
    const typeMatches = (log) =>
    {
     // console.log('checking type match');
      let typeMatch = false;
     // console.log(info, ' info');

      let typesToMatch = [];
      if (info)
        typesToMatch.push('i');

      if (error)
        typesToMatch.push('e');

      if (currentUser.role)       /*Only apply this filter if the role is an admin*/
        if (currentUser.role === 'a')
          if (debug)
            typesToMatch.push('d');

      if (typesToMatch.includes(log.type))
        typeMatch = true;

       // console.log('checking ');
      return typeMatch;
    }

    if (userFiles) {
      if (searchText === "" || searchText === null) {
        let filteredUFs  = JSON.parse(JSON.stringify(userFiles));
       // console.log(filteredUFs, 'filtered ufs');

        filteredUFs.forEach(uf => {
          uf.logs = uf.logs.filter(log => typeMatches(log));
        });

       // console.log('filtering results');
        return filteredUFs.filter(uf => uf.logs.length > 0);
      }

      else {
        let filteredUFs = JSON.parse(JSON.stringify(userFiles));

        try {
          filteredUFs.forEach(uf => {
            uf.logs = uf.logs.filter(log => (((log.message.toLowerCase().indexOf(searchText.toLowerCase())) >= 0)
              && typeMatches(log)));
          });

          return filteredUFs.filter(uf => uf.logs.length > 0);
        }

        catch (e) {
          console.log(e);
          let filteredUFs = JSON.parse(JSON.stringify(userFiles));
          return filteredUFs.filter(uf => uf.logs.length > 0);
        }
      }
    }

    else
      return [];
  }, [searchText, userFiles, currentUser.role, debug, error, info]);

  const getLogCountAfterFilter = (filteredUserFiles) => {
    if (filteredUserFiles)
      try {
        let totalLogs = 0;

        filteredUserFiles.forEach(uf => {
          totalLogs += uf.logs.length;
        });

        return totalLogs;
      }

      catch (e) {
        console.log(e);
        return 0;
      }


    else
      return 0;
  }

  const getType = (log) => {
    let type = 'info';

    if (log.type)
      if (log.type === 'e')
        type = 'error';
      else if (log.type === 'd')
        type = 'warning'; //debug we will show as warning

    return type;
  }

  /*update filtered list if any filter changes*/
  useEffect(() => {
    setFiltered(filterUserFilesAndLogs());
  }, [userFiles, info, error, debug, searchText, filterUserFilesAndLogs]);

  /*side effect just for log count.  Updated when filtered list changes */
  useEffect(() => {
    setLogCount(getLogCountAfterFilter(filtered));
  }, [filtered]);

  /*side effect just for refreshing logs after hours are set */
  useEffect(() => {
    fetchUserFiles(true);
  }, [hours, fetchUserFiles]);

  /*side effect just for timer*/
  useEffect(() => {
    const timer = setInterval(() => tick(), 1000);
    return () => clearInterval(timer); //When we leave the page the timer will clear.
  });

  //Call fetch logs every 60 seconds
  useEffect(() => {
    if (count === 0)
      fetchUserFiles();

  }, [count, fetchUserFiles]);

  return (
    <Card>
      <CardHeader color="primary">
        <GridContainer direction='row' justify="space-between" alignItems="flex-start">
          <GridItem xs={6} sm={6} md={6}>
            <h4 className={classes.cardTitleWhite}>Logs</h4>
            <p className={classes.cardCategoryWhite}>
              Most recent 50 files
        </p>
        <p className={classes.cardCategoryWhite}>
              Most recent 20 logs per file
        </p>
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <p className={classes.cardTitleWhite}> {"Logs auto-rereshing in " + ((count - 60) * -1) + " sec(s)"} </p>
            <p className={classes.cardTitleWhite}> {"Displaying " + logCount + " log(s)"} </p>
          </GridItem>
        </GridContainer>
      </CardHeader>
      <CardBody>
        <GridContainer direction='row' justify="flex-start" alignItems="flex-start">
          <GridItem xs={12} sm={12} md={6}>
            <CustomInput
              labelText="Search Logs"
              inputProps={{ value: searchText, onChange: (event) => handleSearchChanged(event) }}
              id="search"
              formControlProps={{
                fullWidth: true
              }}
            />
          </GridItem>
          <GridItem xs={6} sm={6} md={6}>
            <Button color={"success"} onClick={() => fetchUserFiles(true)} size="sm">Fetch Logs</Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <Button color={"primary"} disabled={hours === 1 ? true : false} onClick={() => setHours(1)} size="sm">1 hour</Button>
            <Button color={"primary"} disabled={hours === 8 ? true : false} onClick={() => setHours(8)} size="sm">8 hours</Button>
            <Button color={"primary"} disabled={hours === 24 ? true : false} onClick={() => setHours(24)} size="sm">24 hours</Button>
            <Button color={"primary"} disabled={hours === -1 ? true : false} onClick={() => setHours(-1)} size="sm">All logs</Button>
          </GridItem>

          <GridItem xs={12} sm={12} md={6}>
            <Button color={info ? "info" : "gray"} onClick={() => setInfo(!info)} size="sm">Info</Button>
            <Button color={error ? "danger" : "gray"} onClick={() => setError(!error)} size="sm">Error</Button>
            {currentUser.role && currentUser.role === 'a' &&
              <Button color={debug ? "warning" : "gray"} onClick={() => setDebug(!debug)} size="sm">Debug</Button>
            }
          </GridItem>
        </GridContainer>
        <div style={{ minHeight: 300, maxHeight: 500, overflow: 'auto' }}>

          {filtered && filtered.length > 0 && filtered.map((userFile, index) =>
            <Accordion key={"userFile " + index} >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1c-content"
                id="panel1c-header"
              >

                <div className={classes.column}>
                  <span >{userFile.fileName + ' - Uploaded Date ' + getDateString(userFile.uploadDate) }</span>
                </div>

                {(userFile.logs && userFile.logs.length >= 0) &&
                  <div className={classes.column}>
                    <span>{userFile.logs.length + " log(s) - " + userFile.logs.filter(log => log.type === 'e').length + " error(s)"}</span>
                  </div>
                }

              </AccordionSummary>
              {userFile.logs && userFile.logs.length > 0 && userFile.logs.map((log, index2) =>
                <div key={"log " + index2} className={classes.alertRoot}>
                  <Alert severity={getType(log)}> {log.message} </Alert>
                </div>
              )}
              <Divider />
              <AccordionActions>
                <Button color={"danger"} onClick={() => clearLogs(userFile.id)} size="sm">Clear Logs</Button>
              </AccordionActions>
            </Accordion>
          )
          }

{!filtered &&
                <div className={classes.column}>
                <span >{'No logs available for the selected filters'}</span>
              </div>
}

{filtered && filtered.length === 0 &&
                <div className={classes.column}>
                <span >{'No logs available for the selected filters'}</span>
              </div>
}

        </div>
      </CardBody>
    </Card>
  );
}
