// REACT
import React from "react";
import Button from '../components/CustomButtons/Button';


export const getDialogButton = (label, onClick) => {

    return <Button
        id={label}
        onClick={onClick}
        label={label}
        color={label === "Save" ? "primary" : "gray"}
    >
      {label}
      </Button>

};

export const userReady = (currentUser) => {

    var uReady = false;

    if(currentUser)
        if(currentUser.email)
            if(currentUser.email !== "")
                uReady = true;

    return uReady;
};