// @material-ui/icons
import Person from "@material-ui/icons/Person";
import PublishIcon from "@material-ui/icons/Publish";
import LibraryBooks from "@material-ui/icons/LibraryBooks";
import Group from "@material-ui/icons/Group";
import MenuBook from "@material-ui/icons/MenuBook";

// core components/views for User layout
import UserProfilePage from "views/UserProfile/UserProfile.js";
import RunsPage from "views/Runs/Runs.js";
import LogsPage from "views/Logs/Logs.js";
import UploadPage from "views/Upload/Upload.js";
import Management from "views/Management/Management.js";
import Ledger from "views/Ledger/Ledger.js";
// core components/views for RTL layout

const dashboardRoutes = [
  /*{
    path: "/dashboard",
    name: "Dashboard",
    admin: false,
    icon: Dashboard,
    component: DashboardPage,
    layout: "/user"
  },*/
  {
    path: "/upload",
    name: "Upload Runs",
    admin: false,
    icon: PublishIcon,
    component: UploadPage,
    layout: "/user",
  },
  {
    path: "/history",
    name: "Runs",
    admin: false,
    icon: "content_paste",
    component: RunsPage,
    layout: "/user",
  },
  {
    path: "/logs",
    name: "Logs",
    admin: false,
    icon: LibraryBooks,
    component: LogsPage,
    layout: "/user",
  },
  /*{
    path: "/notifications",
    name: "Notifications",
    admin: false,
    icon: Notifications,
    component: NotificationsPage,
    layout: "/user"
  },*/
  {
    path: "/account",
    name: "Account",
    admin: false,
    icon: Person,
    component: UserProfilePage,
    layout: "/user"
  },
  {
    path: "/management",
    name: "User Management",
    admin: true,
    icon: Group,
    component: Management,
    layout: "/user"
  },
  {
    path: "/ledger",
    name: "Ledger",
    admin: false,
    icon: MenuBook,
    component: Ledger, //This component can work for admin and individual users
    layout: "/user"
  },
  {
    path: "/adminlogs",
    name: "Admin Logs",
    admin: true,
    icon: LibraryBooks,
    component: LogsPage, //This component can work for admin and individual users
    layout: "/user",
  },
];

export default dashboardRoutes;
