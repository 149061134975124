import React from "react";
import TextField from '@material-ui/core/TextField';


export default function GeneralTextField({meta: { touched, invalid, error }, ...props }) {
  
    const handleChange = (e) => {
        console.log(e);
        console.log('general textfield not working correctly');
    }    

  return (
  <div>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id={props.id}
              label={props.label}
              name={props.id}
              autoComplete={props.autoComplete }
              onChange={props.input ?  props.input.onChange : handleChange} 
              type={props.type}
              autoFocus
              value={props.initialValue ? props.initialValue : (props.input ? props.input.value: null) }
              error={touched && invalid}
              helperText={touched && error}
            />
      </div>
  );
}
