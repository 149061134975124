import React from "react";
import PropTypes from 'prop-types';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { withStyles } from "@material-ui/core/styles";
import DateFnsUtils from '@date-io/date-fns';
import { KeyboardDatePicker } from "@material-ui/pickers";
import moment from "moment";

import GridContainer from "../../components/Grid/GridContainer";

const TEXT_COLOR = {
  color: "#fff",
  fontWeight: "lighter",
}

const styles = theme => ({
  root: {
    "& .MuiInputLabel-animated": TEXT_COLOR,
    "& .MuiInput-root": TEXT_COLOR,
    "& .MuiIconButton-root": TEXT_COLOR
  }
});

//const classes = styles();

const CustomDateDatePicker = props => {
    const { classes } = props;
    return (
      <GridContainer >
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                fullWidth
                className={classes.root}
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-dialog"
                label={props.label || "Choose Date"}
                value={props.dateValue}
                onChange={(newValue) => {
                    props.onChange(moment([newValue.getFullYear(), newValue.getMonth(), newValue.getDate()]))
                }}
                KeyboardButtonProps = {{
                    'aria-label': 'change date',
                }}
                />
            </MuiPickersUtilsProvider>
      </GridContainer>
    );
  };

  CustomDateDatePicker.propTypes = {
    onChange: PropTypes.func.isRequired,
    dateFormat: PropTypes.string,
    label: PropTypes.string,
  };

  export default withStyles(styles)(CustomDateDatePicker);
