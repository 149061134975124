export const BACKEND = process.env.REACT_APP_MAIN_API_URL;
export const FRONTEND = process.env.REACT_APP_MAIN_URL;

/*White Label*/
export const WL = process.env.WhiteLabel;

/*Auth*/
export const LOGIN = `${BACKEND}auth/login`;
export const LOGINCOMET = `${BACKEND}auth/logincomet`;
export const REGISTER = `${BACKEND}auth/register`;
export const VERIFY = `${BACKEND}auth/verify`;
export const RESET = `${BACKEND}auth/reset`;
export const RESET_PASSWORD = `${BACKEND}auth/resetpassword`;
export const RESEND_CONFIRMATION = `${BACKEND}auth/resend`;
export const WHITE_LABEL = `${WL}`;

/*Upload*/
export const USER_SIGN_URL = `${BACKEND}upload/getSignedUrl`;
export const USER_UPLOAD = `${BACKEND}upload/uploadWithModel`;
export const USER_UPLOAD_THROUGH_CLOUD = `${BACKEND}upload/uploadFromCloudStoreAddress`;

export const USER_UPLOAD_CHECK = `${BACKEND}upload/fetchprogress`;
export const FETCH_ALL_MODEL_TYPES = `${BACKEND}upload/fetchAllModelTypes`;

/*Logs*/
export const USER_FETCH_LOGS = `${BACKEND}logs/fetch`;
export const USER_FETCH_LOGS_BY_USERFILE = `${BACKEND}logs/fetchByUserFile`;
export const CLEAR_LOGS = `${BACKEND}logs/clear`;

/*Runs*/
export const USER_FETCH_RUNS = `${BACKEND}runs/fetch`;
export const USER_FETCH_QUEUE = `${BACKEND}runs/fetchqueue`;
export const USER_DOWNLOAD = `${BACKEND}runs/download`;
export const USER_DOWNLOAD_ALL = `${BACKEND}runs/downloadAll`; 
export const USER_CANCEL_RUN = `${BACKEND}runs/cancelbyid`;

/*Users*/
export const USER_FETCH = `${BACKEND}user/fetch`;
export const USER_UPDATE = `${BACKEND}user/update`;
export const USER_FETCH_ALL = `${BACKEND}user/fetchAll`;
export const USER_ADMIN_UPDATE = `${BACKEND}user/adminUpdate`;

/*Ledger*/
export const LEDGER_FETCH_RUNS = `${BACKEND}ledger/fetch`;
export const LEDGER_DOWNLOAD = `${BACKEND}ledger/download`;
export const LEDGER_USER_SUMMARY = `${BACKEND}ledger/usersummaryformonth`;
export const LEDGER_USER_FILE_REPORTS = `${BACKEND}ledger/userreports`;

/*Models*/
export const USER_FETCH_MODEL_TYPES = `${BACKEND}model/modelTypes`;
export const USER_FETCH_MODEL_TEMPLATES = `${BACKEND}model/modelTemplates`;
